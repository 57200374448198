import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import {Link} from 'react-router-dom'
import Typography from '@mui/material/Typography'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import messages from '../../../assets/messages'
import Images from '../../Common/Images'
import config from '../../../config'
import AndroidIcon from '@mui/icons-material/Android'
import PageSubTitle from '../../Common/PageSubTitle'

const closeImage = Images['icon-close.svg']

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
  },
  content: {
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: 700,
      margin: 'auto'
    },
  },
  image: {
    width: 400,
  },
  icon: {
    margin:'0',
    width: '24px',
    height: '24px'
  },
  iconClose: {
    backgroundImage: `url(${closeImage})`,
    width: 46,
    height: 30,
    position: 'relative',
    left: 7,
    backgroundSize: '30px 30px',
    paddingLeft: 10,
    display: 'block',
    backgroundRepeat: 'no-repeat',
    cursor: 'pointer'
  },
  children: {
    textAlign: 'center',
    fontSize:16,
    fontWeight:300
  },
  subtitle1Padding: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  tradingAlertsStep2: {
    paddingTop: 20,
    paddingBottom: 20
  },
  tradingStepStrong: {
    lineHeight: 1.8,
  },
  subtitle1Strong: {
    fontWeight: 500,
  },
})

class TradingAlerts extends Component {

  render() {
    const {classes} = this.props

    return (
      <Grid container className={classes.root} align='center'>
        <Grid container item justifyContent="center" className={classes.subtitle1Padding}>
          <Typography variant='subtitle1'>
            <Trans
              {...messages.tradingAlertsp1Strong}
              components={[<span className={classes.subtitle1Strong}>Black Account Holders</span>]}
            /></Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant='body1'><Trans {...messages.tradingAlertsp2} /></Typography>
        </Grid>
        <Grid container item justifyContent="center" className={classes.tradingAlertsStep2}>
          <PageSubTitle><Trans {...messages.tradingAlertsp3} /></PageSubTitle>
        </Grid>
        <Grid container item justifyContent="center">
          <Typography variant='subtitle1'>
            <Trans
              {...messages.tradingAlertsStep1}
              components={[<span className={classes.subtitle1Strong}>Step1: </span>]}
            /></Typography>
        </Grid>
        <Grid container item justifyContent="center">
          <Grid item xs={12} sm={4}>
            {config.telegram.playstoreLink && <a href={config.telegram.playstoreLink} className={classes.textBlue}>
              <Button color="primary" className={classes.subtitle1Padding}>
                <AndroidIcon /> <Trans {...messages.tradingAlertsbtn1} />
              </Button>
            </a>}
          </Grid>
          <Grid item xs={12} sm={4}>
            <a href={config.telegram.applestoreLink} className={classes.textBlue}>
              <Button color="primary" className={classes.subtitle1Padding}>
                <img src={Images['apple-brands.svg']} className={classes.icon} alt='apple' /> <Trans {...messages.tradingAlertsbtn2} />
              </Button>
            </a>
          </Grid>
          <Grid item xs={12} sm={4}>
            <a href={config.telegram.webLink} className={classes.textBlue}>
              <Button color="primary" className={classes.subtitle1Padding}>
                <AndroidIcon /> <Trans {...messages.tradingAlertsbtn3} />
              </Button>
            </a>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" className={classes.tradingAlertsStep2}>
          <Typography variant='subtitle1'>
            <Trans
              {...messages.tradingAlertsStep2}
              components={[<span className={classes.subtitle1Strong}>Step2: </span>]}
            /></Typography>
        </Grid>
        <Grid container item justifyContent="center" className={classes.tradingAlertsStep2}>
          <Grid item xs={12} sm={6}>
            <Typography variant='subtitle1' className={classes.tradingAlertsStep2}>
              <Trans
                {...messages.accessVipAlertsTextAbove}
                components={[<span className={classes.subtitle1Strong}>Black Account Holder?</span>]}
              /></Typography>
            <Link to="/vip-account">
              <Button
                variant="contained"
                size="large"
                color="primary"
              >
                <Trans {...messages.accessVipAlerts} />
              </Button>
            </Link>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant='subtitle1' className={classes.tradingAlertsStep2}>
              <Trans {...messages.joinBdswissTextAbove} /></Typography>
            <a href={config.telegram.channelLink} className={classes.textBlue}>
              <Button
                variant="contained"
                size="large"
                color="primary"
              >
                <Trans {...messages.joinBdswiss} />
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
TradingAlerts.propTypes = {
  loading: PropTypes.bool,
  error: PropTypes.object
}

export default withStyles(styles, {withTheme: true})(withNamespaces()(TradingAlerts))
