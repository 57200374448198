import React, {Component} from 'react'
import {Trans, withNamespaces} from 'react-i18next'
import {get, isEmpty, some, flowRight as compose} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import FormHelperText from '@mui/material/FormHelperText'
import messages from '../../../assets/messages'
import Grid from '@mui/material/Grid'
import {CHANGE_PASSWORD_MUTATION} from '../../../graphql/mutations'
import {graphql} from 'react-apollo'
import LoadingButton from '../../Common/LoadingButton'
import NotificationBar from '../../Common/NotificationBar'
import {checkPassword} from '../../../common/utils/general'
import {Password} from '../../Common/Password'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import AppContext from '../../Common/contexts/AppContext'
import {Link, withRouter} from 'react-router-dom'
import classNames from 'classnames'


const styles = theme => ({
  formControl:{
    display: 'block',
    margin: theme.spacing(1),
    width: '100%'
  },
  error:{
    color: theme.palette.error.main
  },
  errorMessage:{
    color:  theme.palette.error.main,
    display: 'inline-block',
    verticalAlign: 'bottom',
    margin: '13px 13px 13px 0'
  },
  successDiv:{
    backgroundColor:  theme.palette.lightgreen.color,
    padding: '15px 10px'
  },
  successMessage:{
    color:  theme.palette.lightgreen.textColor
  },
  marginTop:{
    marginTop: '-15px'
  },
  link: {
    color:theme.palette.primary.main,
    cursor: 'pointer',
    fontWeight:400
  },
  topMargin:{
    marginTop: '10px'
  },
  successCTA: {
    color:  theme.palette.notificationBar.greenTextColor,
  },
  topMarginButton:{
    marginTop: '16px'
  },
  margin:{
    margin: '0px 0px 0px 8px',
  },
})
export class ChangePassword extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.textInput = React.createRef()
    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmationPassword: '',
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmationPassword: false,
      messageErrorCurrentPassword: false,
      messageErrorNewPassword: false,
      messageErrorConfirmPassword: false,
      submitMessageError:'',
      loading: false,
      status: '',
      errors: {}
    }
  }
  handleChange(prop,event) {
    const {errors} = this.state
    this.setState({
      [prop]: event.target.value,
      errors: {
        ...errors,
        [prop]: !event.target.value,
      }},this.checkForm)
  }
  handleClickShowPassword(prop) {
    this.setState(state => ({[prop]: !state[prop]}))
  }

  handleKeyDown(prop,event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      document.getElementById(prop).focus()
    }
  }

  checkForm() {
    const {currentPassword, newPassword, confirmationPassword} = this.state
    this.setState({submitMessageError: '', status: ''})
    if (currentPassword && newPassword && confirmationPassword && (newPassword !== confirmationPassword))
      this.setState({messageErrorConfirmPassword: <Trans {...messages.passwordsNotSame} />})
    else
      this.setState({messageErrorConfirmPassword: ''})
    if (newPassword) {
      const passwordError = checkPassword(newPassword)
      if (isEmpty(passwordError)) {
        this.setState({messageErrorNewPassword: ''})
      } else {
        this.setState({messageErrorNewPassword: <Trans {...messages[passwordError]} />})
      }
    }
  }

  handleSubmit = () => {
    const {currentPassword, newPassword, confirmationPassword, messageErrorConfirmPassword, messageErrorNewPassword,
      messageErrorCurrentPassword} = this.state
    const errors ={}
    if (isEmpty(currentPassword))
      errors.currentPassword = true
    if (isEmpty(confirmationPassword))
      errors.confirmationPassword = true
    if (isEmpty(newPassword))
      errors.newPassword = true
    if (some(errors)) {
      this.setState({errors})
      return
    }

    const {t} = this.props
    if (currentPassword && confirmationPassword && newPassword && !messageErrorConfirmPassword && !messageErrorNewPassword && !messageErrorCurrentPassword) {
      this.setState({status: '', loading: true})
      this.props.changePasswordMutation({
        variables: {currentPassword, newPassword}
      }).then((succ) => {
        logEventCustomParams('changePasswordCompleted', {userId: get(this.context, 'clientId')})
        this.setState({loading: false,status: 'success',submitMessageError: ''})
      }).catch((err) => {
        if (err.networkError) {
          this.setState({loading: false,status: 'failure',
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          this.setState({loading: false,status: 'failure',
            submitMessageError: get( err, 'graphQLErrors[0].message') || err.message,
          })
        }

      })
    }
  }

  render() {
    const {classes, history, t} = this.props
    const {loading, currentPassword, showCurrentPassword, showNewPassword, newPassword, showConfirmationPassword, confirmationPassword,
      messageErrorCurrentPassword, messageErrorNewPassword, messageErrorConfirmPassword, submitMessageError, status} = this.state
    logEventCustomParams('changePasswordIntent', {
      source: 'manual',
      userId: get(this.context, 'clientId'),
    })
    const redirectToAddAccount = status==='success' && get(history, 'location.state.accountType')

    return (
      <Grid container>
        {status==='success' &&
          <Grid item xs={12} sm={10}>
            <NotificationBar
              status='success'
              title={<React.Fragment>
                <Trans {...messages.passwordChanged} /> &nbsp;
                {redirectToAddAccount && <Trans {...messages.proceedOpeningAdditionalAccount} components={[<Link
                  to={{
                    pathname: '/accounts/add-account',
                    state: {...get(history, 'location')}
                  }}
                  className={classes.successCTA}> </Link>]} />
                }
              </React.Fragment>}
            />
          </Grid>
        }
        <Grid item xs={12} sm={8} lg={6}>
          <Grid container className={classes.marginTop}>
            <Grid item xs={12}>
              <Password
                classes={classes}
                showPassword={showCurrentPassword}
                onChange={(e)=>this.handleChange('currentPassword',e)}
                onKeyDown={(e)=>this.handleKeyDown('newPassword',e)}
                error={this.state.errors.currentPassword}
                onClickShow={() => this.handleClickShowPassword('showCurrentPassword')}
                fullWidth
                errorText={messageErrorCurrentPassword}
                label={'currentPasswordLowcase'}
                value={currentPassword}
                showErrorText={true}
              />
              <Password
                id={'newPassword'}
                classes={classes}
                showPassword={showNewPassword}
                onChange={(e)=>this.handleChange('newPassword',e)}
                onKeyDown={(e)=>this.handleKeyDown('confirmationPassword',e)}
                error={this.state.errors.newPassword}
                onClickShow={() => this.handleClickShowPassword('showNewPassword')}
                fullWidth
                errorText={messageErrorNewPassword}
                label={'newPassword'}
                value={newPassword}
                showErrorText={true}
              />
              <Password
                id={'confirmationPassword'}
                classes={classes}
                showPassword={showConfirmationPassword}
                onChange={(e)=>this.handleChange('confirmationPassword',e)}
                onKeyDown={(e)=>this.handleKeyDown('loadingButton',e)}
                error={this.state.errors.confirmationPassword}
                onClickShow={() => this.handleClickShowPassword('showConfirmationPassword')}
                fullWidth
                errorText={messageErrorConfirmPassword}
                label={'passwordConfirmationLowCase'}
                value={confirmationPassword}
                showErrorText={true}
              />
              <FormHelperText className={classNames({[classes.helpText]: true, [classes.margin]: true})}><i><Trans {...messages.passwordExplanationFieldNew}/></i></FormHelperText>
              <LoadingButton className={classes.topMarginButton}
                id='loadingButton'
                onClick={this.handleSubmit}
                disabled={loading}>
                <Trans {...messages.continue} />
              </LoadingButton>
              {status==='failure' && <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
              {redirectToAddAccount && <FormHelperText className={classes.topMargin}>{t(messages.newPasswordDashboardAccounts.i18nKey, messages.newPasswordDashboardAccounts.defaults)}</FormHelperText>}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}
export default compose (
  withStyles(styles),
  withRouter,
  withNamespaces(),
  graphql(CHANGE_PASSWORD_MUTATION, {
    name: 'changePasswordMutation',
    options: {
      refetchQueries: ['viewer'],
    },
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
)(ChangePassword)
