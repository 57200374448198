import React, {Component} from 'react'
import {graphql, withApollo} from 'react-apollo'
import {find, get, reject, isEmpty, countBy, map, sumBy, filter, minBy, flowRight as compose, isNil, first, sortBy, includes, lowerCase, isArray} from 'lodash'
import {withNamespaces, Trans} from 'react-i18next'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import withStyles from '@mui/styles/withStyles'
import {currencies, withdrawalPaymentVendors, kycStatuses, withdrawalStatuses} from '@bdswiss/common-enums'
import {Loading} from '../../Common/Loading'
import {withRouter, Link} from 'react-router-dom'
import ChooseMethod from './ChooseMethod'
import ChooseAccount from './ChooseAccount'
import ChooseAvailableOption from './ChooseAvailableOption'
import UiNotification from '../../Common/UiNotification'
import {disableDepositWithSpecificCountries, getItem, isEmptyStr} from '../../../common/utils'
import messages from '../../../assets/messages'
import {blockedWithdrawalAccount, isCentAccount} from '../../../common/utils/accounts'
import {getFormattedAmount, getMissingDocs, getMissingDocumentsPaymentMethods} from '../../../common/utils/general'
import {CREATE_WITHDRAWAL_MUTATION} from '../../../graphql/mutations'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY, PAYMENTS_ACCOUNTS_QUERY, PROFILE_SETTINGS_QUERY, WITHDRAWABLE_METHODS_QUERY} from '../../../graphql/queries'
import NotificationBar from '../../Common/NotificationBar'
import AppContext from '../../Common/contexts/AppContext'
import Images from '../../Common/Images'
import CustomNotificationAlert from '../../Common/CustomNotificationAlert'
import PageSubTitle from '../../Common/PageSubTitle'
import classnames from 'classnames'
import config from '../../../config'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import {withdrawNotificationLinks} from '../../../common/utils/uioptions'

const styles = theme => ({
  modalBody: {
    lineHeight: '1.5rem'
  },
  link: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  emptyListsIcon: {
    ...theme.emptyListsIcon
  },
  noTradesIconWidth: {
    textAlign: 'center'
  },
  makeSureDiv: {
    padding: '10px 20px',
    backgroundColor: theme.palette.lightyellow.color,
    color: theme.palette.black.color,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  zeroMarginTop: {
    marginTop: 0
  },
  highlight: {
    fontWeight: 400
  },
  noteList: {
    paddingLeft: 16,
    '& li': {
      paddingTop: 6
    }
  },
  numberedList: {
    paddingLeft: 16,
    '& li': {
      color: theme.palette.secondary.main,
      paddingLeft: 10,
      fontSize: 14
    }
  },
  hidden: {
    display: 'none'
  },
  errorMessage:{
    color:  theme.palette.error.main,
    marginTop: 10
  },
  capitalizeFirstLetter: {
    display: 'inline-block',
    textTransform: 'lowercase',
    '&::first-letter': {
      textTransform: 'uppercase',
    }
  },
  successCTA: {
    color:  theme.palette.notificationBar.greenTextColor,
  },
  errorCTA: {
    color: theme.palette.notificationBar.redTextColor,
  },
  infoCTA: {
    color: theme.palette.notificationBar.blueTextColor,
  },
  warningCTA: {
    color: theme.palette.notificationBar.yellowTextColor,
  },
})

const pendingWithdrawalStatuses = [
  withdrawalStatuses.pending.key,
  withdrawalStatuses.notProcessed.key,
]

class Withdraw extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const {featuresConfig: {skipKycWithdrawal}} = config
    const {viewer: {kycStatus}} = props
    this.state = {
      form: {
        account: '',
        amount: '',
        method: '',
        availableMethod: {},
        fields: {},
      },
      accountSelected: false,
      refundSelected: false,
      skipRefund: false,
      uiNotification: {
        show: false,
        status: ''
      },
      submitLoading: false,
      kycStatusApproved: kycStatus === kycStatuses.approved.key || skipKycWithdrawal,
      methodsLoading: false,
      currentAccount: {},
      buttonStatus: '',
    }
  }

  accountSelected(accountId, amount) {
    const {accounts, client: apolloClient} = this.props

    this.setState({methodsLoading: true})
    apolloClient.query({query: WITHDRAWABLE_METHODS_QUERY, variables: {id: accountId}, fetchPolicy: 'no-cache'}).then((res) => {
      const withdrawablePaymentMethods = get(res.data, 'viewer.accounts[0].withdrawablePaymentMethods') || []
      const firstVendor = minBy(withdrawablePaymentMethods, 'order')
      const refundSelected = Number(amount) <= get(firstVendor, 'amountLeft', Infinity)
      this.setState(state => ({
        form: {
          ...state.form,
          account: {
            ...find(accounts, {id: accountId}),
            withdrawablePaymentMethods,
          },
          amount: Number(amount),
          availableMethod: refundSelected ? firstVendor : null,
        },
        accountSelected: true,
        methodsLoading: false,
        refundSelected,
      }))
    }).catch((e) => {
      const graphQLError = get(e, 'graphQLErrors[0]')
      this.setState({methodsLoading: false, errorMessage: graphQLError})
    })
  }

  refundSelected(availableMethod) {
    this.setState(state => ({
      form: {
        ...state.form,
        availableMethod,
      },
      refundSelected: true,
    }))
  }

  goBack() {
    this.setState({accountSelected: false, refundSelected: false, skipRefund: false})
  }

  clickButtonEdit() {
    this.setState({buttonStatus: ''})
  }

  createWithdrawalRequest(variables) {
    const {createWithdrawalRequest, viewer} = this.props
    this.setState({submitLoading: true, buttonStatus: ''})
    createWithdrawalRequest({variables}).then((res) => {
      const params = {
        amount: get(variables, 'amount'),
        paymentVendor: get(variables, 'paymentVendor'),
        type: get(res.data, 'withdrawal.type'),
        userId: get(viewer, 'id'),
      }
      logEventCustomParams('withdrawalRequest', params)
      this.setState(state => ({
        uiNotification: {
          show: true,
          status: 'success'
        },
        buttonStatus: 'success',
        form: {
          ...state.form,
          method: variables.paymentVendor,
        },
        submitLoading: false,
        accountSelected: false,
      }))
    }).catch((e) => {
      logEventCustomParams('withdrawalRequestFailed', {
        reason: e.message.replace('GraphQL error: ', ''),
        userId: get(viewer, 'id'),
      })
      this.setState({
        buttonStatus: 'failure',
        uiNotification: {
          show: true,
          status: 'failure',
          errorText: e.message.replace('GraphQL error: ', '')
        },
        submitLoading: false,
      })
    })
  }

  handleChange(prop, value) {
    this.setState(state => ({
      form: {
        ...state.form,
        [prop]: value
      }
    }))
  }

  checkRemainingRefunds(amountToWithdraw, availableMethod) {
    const {refundSelected, form: {amount, account}, withdrawablePaymentMethods} = this.state

    if (refundSelected && (amountToWithdraw < amount)) {
      const currentMethods = !isEmpty(withdrawablePaymentMethods) ? withdrawablePaymentMethods : get(account, 'withdrawablePaymentMethods')
      const updatedMethods = reject(map(currentMethods, (m) => {
        if (m.id === availableMethod.id) {
          m.amountLeft = Number((m.amountLeft - amountToWithdraw).toFixed(2))
        }
        return m
      }), (m) => m.amountLeft <= 0)
      this.setState(state => ({
        uiNotification: {show: false, status: ''},
        refundSelected: false,
        withdrawablePaymentMethods: updatedMethods,
        form: {...state.form, amount: amount - amountToWithdraw},
        availableMethod: {},
        accountSelected: true,
      }))
      if (get(availableMethod, 'id') === 'ldTransfer') {
        this.props.history.push('/transactions')
      }
    } else {
      this.setState({withdrawablePaymentMethods: []})
      this.props.history.push('/')
    }
  }

  copyTradingWithdrawalMsg(copyTradingAccount) {
    const {classes} = this.props
    const accountLabel = copyTradingAccount.copyTrading.accountCopying.accountName
    const isActiveCopying = copyTradingAccount.copyTrading.isActive
    return !isNil(copyTradingAccount) && <NotificationBar
      status="error"
      title={<Trans {...messages[isActiveCopying ? 'copyTradingWithdrawal' : 'copyTradingWithdrawalPending']}
        values={{ibAccount: `${accountLabel} - ${copyTradingAccount.copyTrading.accountCopying.remoteId}`}}
        components={[
          <span className={classes.highlight}>account</span>,
          <Link to={`/accounts/${copyTradingAccount.id}/copyTrading`} className={classes.errorCTA}>  </Link>
        ]}
      />}
    />
  }

  getAccountId(accountSelected) {
    this.setState({
      currentAccount: accountSelected,
      errorMessage: ''
    })
  }

  getMissingDocs(showKYCLink, showPOFLink, notificationStatus) {
    const {classes, location} = this.props
    return map(withdrawNotificationLinks, (link) => {
      const state = link.state({prevPath: location?.pathname})
      return link.show(showKYCLink, showPOFLink) &&
      <Link
        key={link.key}
        to={{pathname: link.path, ...!isNil(state) && {state}}}
        className={classnames(classes[`${notificationStatus}CTA`], classes.capitalizeFirstLetter)}>
        <Trans {...messages[link.translationKey]}/>
      </Link>})
      .filter(Boolean)
      .reduce((prev, curr) => prev ? [prev, <span>&nbsp;&nbsp;</span>, curr] : curr, null)
  }

  getMissingActions(showKYCLink, showPOFLink) {
    const {t} = this.props
    return map(withdrawNotificationLinks, (link) =>
      link.showMissingAction(showKYCLink, showPOFLink) &&
      lowerCase(t(messages[link.missingActionTranslationKey].i18nKey, messages[link.missingActionTranslationKey].defaults)))
      .filter(Boolean)
      .join(', ')
  }

  checkNotificationBarMessage(showKYCLink, showPOFLink) {
    const {t} = this.props
    if (!showKYCLink && !showPOFLink) return

    const notificationStatus = showKYCLink ? 'error' : 'warning'
    const missingDocsLinks = this.getMissingDocs(showKYCLink, showPOFLink, notificationStatus)

    let ctaMessage = null, ctaComponent = null

    if (isArray(missingDocsLinks)) {
      ctaComponent = this.getMissingDocs(showKYCLink, showPOFLink, notificationStatus)
    } else {
      const withdrawNotificationLinksObj = withdrawNotificationLinks[missingDocsLinks.key]
      const translationKey = withdrawNotificationLinksObj.translationKey
      ctaMessage = t(messages[translationKey].i18nKey, messages[translationKey].defaults)
    }

    return <NotificationBar
      status={notificationStatus}
      title={showKYCLink
        ? <Trans {...messages.pendingKYCDocs} />
        : <Trans {...messages.pendingKYCDocsWithActions} values={{missingActions: this.getMissingActions(showKYCLink, showPOFLink)}}/>
      }
      ctaMessage={ctaMessage}
      ctaComponent={ctaComponent}
    />
  }

  render() {
    const {viewerLoading, accountsLoading, classes, accounts, viewer: {id, locale, kycStatus, company, email, ldAcknowledgementDate,
      address: {country}}, history, t, viewer, documentsLoading} = this.props
    const {methodsLoading, errorMessage} = this.state
    const {transactionsConfig} = config
    if (viewerLoading || accountsLoading || documentsLoading || methodsLoading) return <Loading />
    const {accountSelected, form: {account, amount, method, availableMethod}, uiNotification, submitLoading, kycStatusApproved,
      refundSelected, skipRefund, currentAccount, buttonStatus} = this.state
    const accountsWithBalance = sortBy(reject(accounts, a =>
      a.isHidden || (get(a, 'balance') || 0) <= 0 || blockedWithdrawalAccount(a) || (!kycStatusApproved && !a.isDemo)),
    'isDemo', 'desc')

    const methodSubmitMessage = !isEmptyStr(get(uiNotification, 'status'))
      ? [messages[`${get(uiNotification, 'status')}Withdrawal`]] : []
    const groupName = get(withdrawalPaymentVendors[method], 'groupName', 'other')
    if (get(uiNotification, 'status') === 'success' && groupName !== 'other') {
      methodSubmitMessage.push(get(messages, `${groupName}WithdrawalSuccess`))
    }

    const {blockedDeposit, themePreference} = this.context
    // KYC docs related
    const missingKycDocsTotal = getMissingDocs(viewer)
    const missingKycDocs = Boolean(get(countBy(missingKycDocsTotal), 'true'))
    const missingKycDocsWithoutBalance = !kycStatusApproved && missingKycDocs
    // POF docs related
    const paymentMethods = get(viewer, 'paymentMethods', [])
    const missingPOFDocsTotal = getMissingDocumentsPaymentMethods(paymentMethods, true)
    const missingPOFDocs = Boolean(get(countBy(missingPOFDocsTotal), 'false')) || isEmpty(paymentMethods)

    const withdrawablePaymentMethods = get(this.state, 'withdrawablePaymentMethods') || get(account, 'withdrawablePaymentMethods')
    const shouldSelectAccount = !accountSelected && !isEmpty(accountsWithBalance)
    const shouldSelectRefund = accountSelected && !isEmpty(withdrawablePaymentMethods) && !refundSelected && !skipRefund
    const shouldSelectMethod = (shouldSelectRefund && refundSelected) || (!shouldSelectRefund && accountSelected)
    const amountToWithdraw = Number((!isEmpty(availableMethod) && (amount < get(availableMethod, 'amountLeft') ? amount : get(availableMethod, 'amountLeft')))
      || amount)

    const getCurrentAccount = (!accountSelected && isEmpty(currentAccount)) ? first(accountsWithBalance) : currentAccount
    const blockedWithdrawalsCopyTrading = get(getCurrentAccount, 'withdrawalsBlocked')

    return <div>
      <CustomNotificationAlert/>
      {isEmpty(accountsWithBalance) && <React.Fragment>
        {kycStatus ===  kycStatuses.rejected.key
          ? this.checkNotificationBarMessage(missingKycDocs, missingPOFDocs)
          : !disableDepositWithSpecificCountries(viewer)
            ? (
              <NotificationBar status="info"
                title={<Trans {...messages.withdrawalAccountWithoutBalance} />}
                ctaMessage={!blockedDeposit ? <Trans {...messages.depositNow} /> : null}
                ctaAction={() => !blockedDeposit ? history.push('/transactions') : null}
              />
            )
            : (
              <NotificationBar status="info" title={<Trans {...messages.withdrawalAccountWithoutBalanceWithoutDeposit} />} />
            )
        }
        <Grid container
          direction="column"
          justifyContent="space-between"
          alignItems="center">
          <Grid item className={classes.noTradesIconWidth}>
            <img className={classes.emptyListsIcon} src={Images[`payments-history-empty-${themePreference}.png`]} alt='noTrades' />
          </Grid>
        </Grid>
      </React.Fragment>}
      {!isEmpty(accountsWithBalance) && <Grid item xs={12}>{this.checkNotificationBarMessage(missingKycDocsWithoutBalance, missingPOFDocs)}</Grid>}
      {blockedWithdrawalsCopyTrading && this.copyTradingWithdrawalMsg(getCurrentAccount)}
      {(shouldSelectAccount || shouldSelectRefund) && <Grid container>
        <Grid item xs={12} lg={8} sx={{ml: {md: 0}}}>
          {shouldSelectAccount && <ChooseAccount
            accounts={accountsWithBalance}
            onContinue={(accountId, amount) => this.accountSelected(accountId, amount)}
            getSelectedAccount={(accountSelected) => this.getAccountId(accountSelected)}
            viewer={{
              locale: locale || getItem('locale', 'en'),
              kycStatus,
            }}
            history={history}
            accountId={account.id}
            amount={amount && isCentAccount(account) ? amount / currencies.CUD.baseCurrencyRate : amount}
            disabledAction={!!blockedWithdrawalsCopyTrading}
            classes={classes}
            errorMessage={errorMessage}
          />}
        </Grid>
        {blockedWithdrawalsCopyTrading && <Grid item xs={12} sm={5} md={6}>
          <Grid container className={classes.makeSureDiv} spacing={2}>
            <Grid item xs={12}>
              <PageSubTitle><Trans {...messages.description} /> </PageSubTitle>
              <ul className={classnames(classes.noteList,classes.zeroMarginTop)}>
                <li><Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP1} /> </Typography></li>
                <li><Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP2} /> </Typography></li>
                <li>
                  <Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP3} /> </Typography>
                  <ol className={classes.numberedList}>
                    <li><Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP3T1} /> </Typography></li>
                    <li><Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP3T2} /> </Typography></li>
                    <li><Typography variant="body1"> <Trans {...messages.copyTradingWithdrawalP3T3} /> </Typography></li>
                  </ol>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Grid>}
        {shouldSelectRefund && <ChooseAvailableOption
          account={account}
          withdrawablePaymentMethods={withdrawablePaymentMethods}
          amount={amount}
          goBack={() => this.goBack()}
          onContinue={(availableMethod) => this.refundSelected(availableMethod)}
          viewer={{
            locale: locale || getItem('locale', 'en'),
            kycStatus,
          }}
          history={history}
          skipRefund={() => this.setState({skipRefund: true})}
          errorMessage={errorMessage}
        />}
      </Grid>}

      {shouldSelectMethod && <ChooseMethod
        account={account}
        amount={amountToWithdraw}
        viewer={{
          locale: locale || getItem('locale', 'en'),
          kycStatus,
          company,
          country,
          clientId: id,
          ldAcknowledgementDate
        }}
        availableMethod={!isEmpty(withdrawablePaymentMethods) ? availableMethod : {}}
        onSubmit={(variables) => this.createWithdrawalRequest(variables)}
        goBack={() => this.goBack()}
        clickButtonEdit={() => this.clickButtonEdit()}
        submitLoading={submitLoading}
        buttonStatus={buttonStatus}
        onChangeMethod={() => this.setState(state => ({
          form: {
            ...state.form,
            availableMethod: null,
          },
        }))}
      />}
      {get(uiNotification, 'show') && <UiNotification
        open={get(uiNotification, 'show')}
        status={get(uiNotification, 'status')}
        type="payment"
        buttonMessage={t(messages.close.i18nKey, messages.close.defaults)}
        onClose={(status) => {
          if (status === 'success') {
            this.checkRemainingRefunds(amountToWithdraw, availableMethod)
          } else {
            this.setState({uiNotification: {show: false, status: ''}})
          }
        }}
      >
        {methodSubmitMessage.map((message, i) =>{
          const removeSuccessMessageContent = [withdrawalPaymentVendors.ldTransfer.key]
          const showMessage = !includes(removeSuccessMessageContent, get(availableMethod, 'vendor'))
          return showMessage && <Typography variant='body1' className={classes.modalBody} key={i}>
            <Trans
              {...message}
              values={{
                amount: getFormattedAmount({amount, currency: account.currency, locale}),
                email,
                days: get(transactionsConfig, `withdrawal.${method}.workingDays`) || '5-10',
              }}
              components={[
                <strong>amount</strong>,
                <span className={!get(transactionsConfig, 'withdrawal.successMessage24hours') ? classes.hidden : ''}>message</span>,
                <span className={get(transactionsConfig, 'withdrawal.successMessage24hours') ? classes.hidden : ''}>message</span>,
              ]}
            />
          </Typography>})}
        {get(uiNotification, 'errorText') &&  <Typography variant='body1' className={classes.modalBody}>
          ({get(uiNotification, 'errorText')})
        </Typography>}
      </UiNotification>}
    </div>
  }
}

export default compose(
  withStyles(styles, {withTheme: true}),
  withRouter,
  withApollo,
  withNamespaces(),
  graphql(CREATE_WITHDRAWAL_MUTATION, {
    name: 'createWithdrawalRequest',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  }),
  graphql(PAYMENTS_ACCOUNTS_QUERY, {
    props: ({data: {error, loading: accountsLoading}, data}) => {
      const accounts = reject(get(data, 'viewer.accounts'), 'isArchived')
      return {
        error,
        accountsLoading,
        accounts: map(accounts, (a) => {
          const pendingAmount = sumBy(filter(a.withdrawals, (w) => pendingWithdrawalStatuses.includes(w.status)), 'amount') || 0
          return {
            ...a,
            pendingAmount,
          }
        }),
      }
    },
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error, loading: viewerLoading}, data}) => {
      const viewer = get(data, 'viewer')
      return {
        error,
        viewerLoading,
        viewer,
      }
    }
  }),
  graphql(PROFILE_SETTINGS_QUERY, {
    props: ({data: {error, loading: documentsLoading, viewer}}) => {
      const documents = get(viewer, 'documents', [])
      return {
        error,
        documentsLoading,
        documents,
      }
    }
  }),
)(Withdraw)
