import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import withStyles from '@mui/styles/withStyles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {Loading} from '../Common/Loading'
import {withNamespaces, Trans} from 'react-i18next'
import {accountTypes, accountCategories, clientTypes, countries, kycStatuses,
  whiteLabels} from '@bdswiss/common-enums'
import _, {get, includes, filter, size, every, isEmpty, flowRight as compose, map, find, keys,
  orderBy, upperCase, last} from 'lodash'
import {graphql, withApollo} from 'react-apollo'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY, CLIENT_REFER_CAMPAIGN_QUERY, REFERRED_BY_QUERY,
  LOCAL_DEPOSITOR_DETAILS} from '../../graphql/queries'
import {GET_COMPETITIONS_APPLICABLE_COUNT_QUERY} from '../../graphql/competitions/queries'
import messages from '../../assets/messages'
import PageTitle from '../Common/PageTitle'
import PageBody from '../Common/PageBody'
import AccountCard from './AccountCard'
import {Link, Redirect} from 'react-router-dom'
import MifirPopup from './MifirPopup'
import CompanyRetailClosurePopup from './CompanyRetailClosurePopup'
import {isForexAccount, isPAMMFundManagerAccount, isAffiliatesAccount, isIntroducingBrokerAccount, isIBClient, isIBClientSubIb,
  hasWalletProductAccount, getAllowedAccounts, isBitnukAccount, isLdWalletAccount, isLdClientWalletAccount,
  hasCopyTradingAccount} from '../../common/utils/accounts'
import {isMobile, getCookie, setCookie} from '../../common/utils/browser'
import Footer from '../App/Footer'
import ClientNotificationBar from './ClientNotificationBar'
import NotificationBar from '../Common/NotificationBar'
import AppContext from '../Common/contexts/AppContext'
import config from '../../config'
import {AFFILIATE_SSO_LINK_QUERY, IB_SSO_LINK_MUTATION, IB_INTERESTED_MUTATION,
  ADD_METADATA_TO_CLIENT_MUTATION} from '../../graphql/mutations'
import {AlertDialog, CustomDialog} from '../Common/Dialog'
import CFDNoticePopup from './CFDNoticePopup'
import UiNotification from '../Common/UiNotification'
import IbAccountSelectModal from './Ib/IbAccountSelectModal'
import Images from '../Common/Images'
import classNames from 'classnames'
import {getItem, isMenaCountry, removeItem, storeItem} from '../../common/utils'
import PartnerMigrationPopup from './PartnerMigrationPopup'
import MigrationToEuPopup from './MigrationToEuPopup'
import {accountCategoriesDetails, referAndEarnDetails} from '../../common/utils/uioptions'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import moment from 'moment'
import {logEventCustomParams} from '../../common/utils/firebaseEvents'
import {Accordion, AccordionSummary, AccordionDetails, Table, TableRow, TableHead, TableCell, TableBody, ClickAwayListener, Tooltip, SvgIcon, Button} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import HelpIcon from '@mui/icons-material/HelpOutline'
import WhiteLabelTerminationPopup from './WhiteLabelTerminationPopup'
import {ReactComponent as AddNewAccountIcon} from '../../assets/images/addNewAccount.svg'
import LoadingButton from '../Common/LoadingButton'

require('intl/locale-data/jsonp/en')

const styles = theme => ({
  main: {
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: `${theme.sidebarWidth}`,
  },
  tabsRoot: {
    marginTop: 0,
  },
  header: {
    marginBottom: 40,
    display: 'inline-block'
  },
  flexGrow:{
    flexGrow:1
  },
  zeroPaddingLeft:{
    paddingLeft:0
  },
  addAccount:{
    display: 'flex',
    alignItems: 'center',
    '&:hover':{
      backgroundColor: 'transparent',
    },
  },
  addAccountMobile:{
    display: 'block',
    fontSize: 25,
    position: 'absolute',
    [theme.direction === 'rtl' ? 'left' : 'right']: 12,
    top: 14,
    [theme.breakpoints.down('md')]: {
      fontSize: 23,
    },
  },
  textLink: {
    color: theme.palette.primary.main,
  },
  competitionImg: {
    height: 25,
    position: 'absolute',
    [theme.direction === 'rtl' ? 'right' : 'left']: -35,
    top: -2,
    [theme.breakpoints.down('md')]: {
      [theme.direction === 'rtl' ? 'right' : 'left']: -25,
      height: 23,
    },
  },
  displayInline: {
    display: 'inline-flex',
    '&.disabled': {
      pointerEvents: 'none',
    },
  },
  competitionDivider: {
    lineHeight: '15px',
    minHeight: 15,
  },
  competitionsLink: {
    [theme.direction === 'rtl' ? 'borderLeft' : 'borderRight']: `1px solid ${theme.palette.primary.main}`,
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 30,
  },
  competitionsLinkMobile: {
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 0,
    paddingRight: 11,
    border: 'none'
  },
  enterCompetition: {
    [theme.direction === 'rtl' ? 'left' : 'right']: 37,
    position: 'relative',
  },
  enterCompetitionMobile: {
    fontSize: 14,
    [theme.direction === 'rtl' ? 'left' : 'right']: 17,
  },
  competitionSpan: {
    position: 'relative'
  },
  maxAccountsDiv: {
    textAlign: 'center',
    padding: '15px 0'
  },
  pointer: {
    cursor: 'pointer',
  },
  dot: {
    color: theme.palette.error.main,
    fontSize: 10,
    marginLeft: 5
  },
  ol: {
    listStylePosition: 'inside',
    [theme.breakpoints.down('md')]: {
      listStylePosition: 'inherit',
      textAlign: 'left',
    }
  },
  li: {
    paddingBottom: 10,
  },
  notificationTitle: {
    maxWidth: 430,
    margin: '0 auto'
  },
  referralDiv: {
    maxWidth: 500,
    textAlign: 'left',
    margin: '0 auto',
  },
  inline: {
    display: 'inline'
  },
  accordionRoot: {
    paddingBottom: 30
  },
  icon: {
    width: 15,
    height: 15,
    marginLeft: 3,
  },
  newAccountIcon: {
    marginLeft: theme.direction === 'rtl' ? 10 : 0,
    verticalAlign: 'middle',
    '& path': {
      fill: theme.palette.primary.main,
    },
    '&.disabled': {
      '& path': {
        fill: theme.palette.grey.color,
      },
    },
  },
  newAccountIconText: {
    color: theme.palette.primary.main,
    '&.disabled': {
      color: theme.palette.grey.color,
    },
  },
  buttonContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'baseline',
  },
  accountCardItem: {
    [theme.breakpoints.down('md')]: {
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeft: 0,
    }
  },
  accountCardItemWrapper: {
    '& .MuiGrid-item': {
      '&:nth-child(odd)': {
        [theme.direction === 'rtl' ? 'paddingRight' : 'paddingLeft']: 0,
      },
      '&:nth-child(even)': {
        [theme.direction === 'rtl' ? 'paddingLeft' : 'paddingRight']: 4,
      },
    }
  },
  successCTA: {
    color:  theme.palette.notificationBar.greenTextColor,
  },
  iconAddAccount: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
  },
  customTooltip: {
    backgroundColor: theme.palette.primary.main,
  },
  arrowStyle: {
    color: theme.palette.primary.main,
  },
  zuluImage: {
    textAlign: 'center',
    display: 'flex',
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    color: theme.palette.grey[400],
    cursor: 'pointer'
  },
  dialog: {
    '& .MuiDialogTitle-root': {
      textAlign: 'center',
      backgroundColor: theme.palette.mode === 'dark' ? '#181818' : '#fff'
    },
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.mode === 'dark' ? '#181818' : '#fff',
    }
  },
  dialogMobile: {
    '& .MuiDialogTitle-root': {
      textAlign: 'center',
      padding: 0,
    },
    '& .MuiDialogContent-root': {
      overflowY: 'hidden'
    },
    '& .MuiBackdrop-root': {
      backgroundColor: theme.palette.mode === 'dark' ? '#000' : 'none'
    },
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.mode === 'dark' ? '#181818' : '#fff'
    },
    '& .MuiDialog-paper': {
      margin: 0,
      padding: 24,
      fontSize: 16,
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.15px'
    }
  },
  buttonGroup: {
    display: 'flex',
    paddingTop: 10,
  },
  copyTradingText: {
    textAlign: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  }
})

class Accounts extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const appropTestScore = props.history.location.search.split('score=')[1]
    const accountCreated = props.history.location.search.split('accountCreated=')[1]
    this.state = {
      activeTab: 0,
      step: 0,
      appropTestScore: appropTestScore,
      showAppropTestMessage: appropTestScore ? true : false,
      showMifirPopup: true,
      showCFDNoticePopup: true,
      showAffiliatePopup: true,
      showAffiliatePopupClick: false,
      showRedirectionModal: true,
      showCompanyRetailClosurePopup: true,
      showAccountCreatedMessage: accountCreated ? true : false,
      showIBPopup: false,
      showIbAccountSelectionModal: false,
      ibAccountSelected: {},
      showRefreshNotification: false,
      showPartnerMigration: true,
      showBitnukPopup: false,
      showReferral: true,
      openDeposit: false,
      showWhiteLabelTerminationPopup: true,
      showZuluPopup: false,
    }
  }

  componentDidMount() {
    const {location} = this.props
    this.timerComponentLoad = setTimeout(() => this.checkComponent(), 30000)
    if (get(location, 'state.showBitnukPopup')) this.setState({showBitnukPopup: !!location.state.showBitnukPopup})
  }

  componentWillUnmount () {
    clearTimeout(this.timerComponentLoad)
  }

  checkComponent() {
    const {loading, loadingClient, error, errorClient, accounts} = this.props
    if (loading || loadingClient || error || errorClient || !accounts || isEmpty(accounts)) {
      this.setState({showRefreshNotification: true})
    }
  }


  decreaseStep() {
    const {step} = this.state
    const {history} = this.props;
    (step > 0) ? this.setState({step:step-1}): history.push('/')
  }
  setStep(step) {
    this.setState({step})
  }

  toAffiliatePortal(account) {
    const {client: apolloClient} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')

    apolloClient.query({query: AFFILIATE_SSO_LINK_QUERY, variables: {accountId: account.id}, fetchPolicy:'network-only'}).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  toIBPortal(account, ibId) {
    const {createIBSingleSignOnLink} = this.props
    const newTab = window.open('about:blank', '_blank')
    newTab.document.write('<h4>Please wait</h4>')
    createIBSingleSignOnLink({variables: {accountId: account.id, ibId}}).then((res) => {
      newTab.location = res.data.data.link
    }).catch((e) => {
      newTab.close()
    })
  }

  openAccount(account) {
    const verifiedAffiliate = (isAffiliatesAccount(account) && includes(['Approved'],
      get(account, 'serviceFields.affiliateStatus')))
    const approvedIb = (isIntroducingBrokerAccount(account) && get(account, 'portalAccess', false))
    const {history, kycStatus} = this.props
    if (isAffiliatesAccount(account)) {
      !verifiedAffiliate ? this.setState({showAffiliatePopupClick: true, showAffiliatePopup: true}) : this.toAffiliatePortal(account)
    } else if (isLdWalletAccount(account)) {
      history.push('/transactions/local-depositor-transfer')
    } else if (isLdClientWalletAccount(account)) {
      history.push('/transactions/withdraw')
    } else if (isIntroducingBrokerAccount(account)) {
      if (!approvedIb) {
        this.setState({showIBPopup: true})
      } else if (get(account, 'ibId', []).length > 1) {
        this.setState({showIbAccountSelectionModal: true, ibAccountSelected: account})
      } else {
        this.toIBPortal(account)
      }
    } else if (isBitnukAccount(account) && kycStatus !== kycStatuses.approved.value) {
      this.setState({showBitnukPopup: true})
    }
    else {
      history.push(`/accounts/${account.id}`)
    }
  }

  hideMifirPopup() {
    this.props.history.push('/accounts')
    this.setState({showMifirPopup: false})
  }

  hideCompanyRetailClosurePopup() {
    this.setState({showCompanyRetailClosurePopup: false})
  }

  hasOnlyWalletAccounts(accounts) {
    return size(accounts) > 0 &&
      every(accounts, (a) => ['fiatWallet', 'cryptoWallet'].includes(accountTypes[a.__typename].category))
  }

  hideAffiliatePopup() {
    this.props.history.push('/accounts')
    this.setState({showAffiliatePopup: false, showAffiliatePopupClick:false})
  }

  continueAffiliatePopup(continueAffiliatePopup) {
    if (continueAffiliatePopup) {
      this.props.history.push( {
        pathname: '/settings/profile/due-diligence',
        state: {force: true}
      })
    }

    this.setState({showAffiliatePopup: false, showAffiliatePopupClick:false})
  }

  hideCFDNoticePopup() {
    this.setState({showCFDNoticePopup: false})
  }

  hideRedirectionsModal() {
    removeItem('refEntity_redirect')
    if (getCookie('missing_approp_test') === 'true') {
      this.props.history.push('/settings/profile/appropriateness-test')
    } else {
      this.setState({showRedirectionModal: false})
    }
  }

  hideWhiteLabelTerminationPopup() {
    this.setState({showWhiteLabelTerminationPopup: false}, () => storeItem('whiteLabelTerminationPopupClosed', true))
  }

  renderLinks(showCompetitions) {
    const {clientType} = this.context
    const {classes, viewer} = this.props
    const disableAddNewAccount = clientTypes[clientType]?.disableAddNewAccount || viewer?.isContractor

    return <React.Fragment>
      {showCompetitions && <Link to={'/trading-competitions'} className={classNames(classes.displayInline, isMobile() ? classes.competitionsLinkMobile : classes.competitionsLink)}>
        <Typography variant={'body2'} color="primary" className={classes.competitionDivider}>
          <span className={classNames(classes.enterCompetition, (isMobile()) ? [classes.addAccountMobile,classes.enterCompetitionMobile] : '')}>
            <span className={classes.competitionSpan}><img src={Images['competitions.png']} alt={'competitions'} className={classes.competitionImg}/></span>
            {!isMobile() ? <Trans {...messages.enterCompetition} /> : <Trans {...messages.competition} /> }
          </span>
        </Typography>
      </Link>}
      {!isMobile() &&
        <Link
          to={'/accounts/add-account'}
          className={classNames(classes.displayInline, disableAddNewAccount ? 'disabled' : '')}
          onClick={() => logEventCustomParams('newTradingAccountIntent', {userId: get(viewer, 'id'), source: 'Direct'})}
        >
          <Grid className={classes.buttonContainer}>
            <Grid item>
              <SvgIcon
                component={AddNewAccountIcon}
                className={classNames(classes.newAccountIcon, disableAddNewAccount ? 'disabled' : '')}
              />
            </Grid>
            <Grid item className={classes.addAccount}>
              <Typography className={classNames(classes.newAccountIconText, disableAddNewAccount ? 'disabled' : '')} variant={'body2'}>
                <Trans {...messages.newAccount} />
              </Typography>
            </Grid>
          </Grid>
        </Link>}
    </React.Fragment>
  }

  removeFirstReferral() {
    this.props.addMetadataToClient({variables: {metadata: JSON.stringify({firstReferrer: ''})}}).then().catch()
  }

  becomeAnIb() {
    const {history} = this.props
    const {allowIbAccount} = config
    this.removeFirstReferral()
    if (allowIbAccount) {
      history.push('/accounts/add-account')
    } else {
      this.props.ibInterested().then(() => {
        this.context.showNotification({
          type: 'document-upload',
          status: 'success',
          title: 'ibInterestSubmitted',
          content: this.props.t(messages.finalisePartnerRegistration.i18nKey, messages.finalisePartnerRegistration.defaults),
          buttonMessage: this.props.t(messages.close.i18nKey, messages.close.defaults),
          onClose: () => history.push('/referAFriend'),
        })
      }).catch({})
    }
  }

  referralNotificationContent(referralDetails) {
    const {classes, referCampaign, t} = this.props
    const {minDeposit, referralInstructions, currency} = referAndEarnDetails
    const referTiers = orderBy(get(referCampaign, 'referTiers'), 'ftdAmount', 'desc')

    return <Grid container spacing={1} className={classes.referralDiv}>
      <Grid item xs={12}>
        <Typography variant='body1' className={classes.notificationTitle}>
          <Trans {...messages.referReferralSubtitle} values={{days: get(referralDetails, 'conditions.days')}}/>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ol className={classes.ol}>
          {map(referralInstructions, (key) => <li key={key} className={classes.li}>
            <Typography variant='body1' className={classes.inline}><Trans {...messages[key]}
              values={{deposit: minDeposit}}/></Typography>
          </li>)
          }
        </ol>
      </Grid>
      <Grid item xs={12}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant='body1'><Trans {...messages.howMuchToEarn} /></Typography>
          </AccordionSummary>
          <AccordionDetails classes={{root: classes.accordionRoot}}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>
                        <Trans {...messages.deposit} />
                        <ClickAwayListener onClickAway={() => this.setState({openDeposit: false})}>
                          <Tooltip title={t(messages.amountFtdUsdReferralFriend.i18nKey, messages.amountFtdUsdReferralFriend.defaults)}
                            placement="top"
                            PopperProps={{
                              disablePortal: true,
                            }}
                            onClose={() => this.setState({openDeposit: false})}
                            open={this.state.openDeposit}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            className={classNames(classes.pointer, classes.icon)}
                            classes={{tooltip: classNames(classes.customTooltip, classes.toolTipPartner)}}>
                            <HelpIcon className={classes.icon} onClick={() => this.setState({openDeposit: true})}/>
                          </Tooltip>
                        </ClickAwayListener>
                      </TableCell>
                      <TableCell><Trans {...messages.requiredVolume} /></TableCell>
                      <TableCell><Trans {...messages.yourReward} /></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {map(referTiers, (tier, index) => {
                      let prevFtdAmount = parseFloat(get(referTiers, `[${index+1}].ftdAmount`) || minDeposit)
                      if (index && prevFtdAmount !== minDeposit) prevFtdAmount = prevFtdAmount + 1
                      return <TableRow key={index}>
                        <TableCell>{(!index) ? `${get(tier, 'ftdAmount')}+` : `${currency}${prevFtdAmount} - ${currency}${get(tier, 'ftdAmount')}`}</TableCell>
                        <TableCell>{get(tier, 'volumeRequirement')} <Trans {...messages.lots} /></TableCell>
                        <TableCell>{currency}{get(tier, 'refereeReward')}</TableCell>
                      </TableRow>
                    })}
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='caption'><Trans {...messages.noteDepositsRefer} /></Typography>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  }

  referralNotificationReferredContent() {
    const {classes} = this.props
    const {referredSomeone} = referAndEarnDetails
    return <Grid container spacing={1} className={classes.referralDiv}>
      <Grid item xs={12}>
        <Typography variant='body1' className={classes.notificationTitle}>
          <Trans {...messages.congratulationsReferredSomeoneText} components={[<br key={'br'} />]}/>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ol className={classes.ol}>
          {map(referredSomeone, (key) => <li key={key} className={classes.li}>
            <Typography variant='body1' className={classes.inline}><Trans {...messages[key]}/></Typography>
          </li>)
          }
        </ol>
      </Grid>
    </Grid>
  }

  addZuluCountToMetadata (currentCount, counter) {
    this.props.addMetadataToClient({
      variables: {
        metadata: JSON.stringify({
          copyTradingNotificationAppearanceTimes: currentCount + counter
        })
      }}).then().catch()
  }

  closeZuluPromo() {
    const {viewer: {copyTradingNotificationAppearanceTimes}} = this.props
    this.setState({showZuluPopup: false})
    this.addZuluCountToMetadata(copyTradingNotificationAppearanceTimes, 1)
    setCookie('show_copyTrading_promo', true)
  }

  copyTradingTradePromoPopupAction(action) {
    const {locale} = this.context
    const {history, viewer: {copyTradingNotificationAppearanceTimes}} = this.props
    const {weblinks} = config
    this.setState({showZuluPopup: false})
    setCookie('show_copyTrading_promo', true)
    this.addZuluCountToMetadata(copyTradingNotificationAppearanceTimes, 5)
    switch (action) {
      case 'learnMore':
        window.open(get(weblinks, 'copyTradingTradeLink', '').replace('{lang}', locale), '_blank')
        break
      case 'startCopyTrading':
        history.push('/accounts/add-account')
        break
      default:
        break
    }
  }

  render() {
    const {classes, loading, accounts, categories, nationality, mifirId, mifirType, history,
      country, t, depositedAmount, kycStatus, spanishCfdNotice, loadingClient, competitions, allowedAccountTypes, viewer, referredBy, localDepositorSubscriptionsCount} = this.props
    const {activeTab, showMifirPopup, showCFDNoticePopup, showBitnukPopup,
      showRedirectionModal, showAccountCreatedMessage, showIBPopup, showCompanyRetailClosurePopup,
      showIbAccountSelectionModal, ibAccountSelected, showRefreshNotification, showPartnerMigration, showReferral, showWhiteLabelTerminationPopup} = this.state
    const {blockedDeposit, clientType, companyObject, locale, themePreference} = this.context
    const {ibPartnersEmail, featuresConfig: {competitions: competitionsAllowed}, name, common: {nationalitiesDisabledMifir},
      euRegulation: {showTermsPopup}, ibPartnersLink, enableCompanyRetailClosurePopup} = config
    if ((loading || loadingClient || !accounts || isEmpty(accounts)) && !showRefreshNotification) return <Loading />
    const activeCategories = activeTab === 0 ? [] : categories[activeTab-1].accountTypeCategories
    const filteredAccounts = accounts
      .map(account => ({...account, accountType: accountTypes[account.__typename]}))
      .filter(account => activeTab === 0 || activeCategories.includes(account.accountType.category))
    const emptyMifir = (mifirId === null || mifirId === '') || (mifirType === null || mifirType === '')
    const checkAff = (clientType === clientTypes.affiliate.value) ||
      clientType === clientTypes.affiliateCorporate.value

    const mifirCondition = depositedAmount > 0 && config.featuresConfig.mifirPopup && emptyMifir
      && !checkAff && !includes(nationalitiesDisabledMifir, nationality) && !this.hasOnlyWalletAccounts(accounts)

    const liveAccounts = filter(accounts, (a)=> isForexAccount(a) && !a.isDemo)
    const hasNonReadonlyLiveAccounts = filter(liveAccounts, (account) => !account.isReadOnly)
    const hasSignedCfdNotice = _.find(spanishCfdNotice, {noticeId: 'generalCFDDisclaimer'})
    const cfdNoticeCondition = config.featuresConfig.spanishCfdNotice && country === countries.es.key && !hasSignedCfdNotice
      && !isEmpty(hasNonReadonlyLiveAccounts) && depositedAmount > 0

    let redirected
    let wlRedirection
    if (get(this.props, 'location.search')) {
      redirected = this.props.location.search.indexOf('refEntity') > 0
      wlRedirection = this.props.location.search.indexOf('wlRedirection') > 0
      if (redirected && config.featuresConfig.migrateCompany.enable && !getCookie('__mig-rejected')
      && !!getCookie('RegulationAccepted') && !hasWalletProductAccount(accounts) && !get(viewer, 'euMigration')) {
        return <Redirect to='/migrate' />
      }
    }

    let showCompetitions = competitionsAllowed && competitions > 0 && !includes(get(competitionsAllowed, 'blockedCountries'), country.toUpperCase())
    if (isIBClient(viewer.clientType) && !isIBClientSubIb(viewer.clientType, viewer.registrationCampaigns)) showCompetitions = false

    const accountLimitReached = getAllowedAccounts(accounts, companyObject, t, allowedAccountTypes || [], viewer)
    const showEuMigration = !!showTermsPopup && get(viewer, 'fromCompany') && !get(viewer, 'acceptEuMigrationTerms')
    const multipleReglation = get(viewer ,'multipleRegulation') && showPartnerMigration && !getItem('migrationPopupClosed')
    const hasBitnuk = find(filteredAccounts, (filteredAccount) => isBitnukAccount(filteredAccount) && !filteredAccount.balance)
    const today = moment().format()
    const referralDetails = getItem('referralDetails')
    const showReferredSomeone = get(viewer, 'hasFirstReferrer')
    const checkRedirected = !!getItem('refEntity_redirect') || redirected
    const whiteLabelTerminationObject = !getItem('whiteLabelTerminationPopupClosed') && find(whiteLabels, {key: get(viewer, 'whiteLabel')})?.terminationPopup
    const nationalIdRequired = get(config, 'gdcSuppportedCountriesExtraFields.nationalId.countries', []).includes(country)
    const isMobileDevice = isMobile()
    const showCopyTradePromo = !isMenaCountry(upperCase(country)) && get(viewer, 'copyTradingNotificationAppearanceTimes') < 5 &&
      !hasCopyTradingAccount(accounts) && hasCopyTradingAccount(accountLimitReached) && !getCookie('show_copyTrading_promo') && last(get(viewer, 'email').split('@')) === 'bdswiss.com'
    return (
      <React.Fragment>
        {checkRedirected && showRedirectionModal && !showEuMigration && <AlertDialog
          open={showRedirectionModal}
          title={t(messages.entityRedirectionHeader.i18nKey, {trademark: get(companyObject, 'trademark')})}
          children={<Typography variant="body1"> <Trans {...messages.entityRedirectionMessage} values={{currentEntity: companyObject.trademark}} /> </Typography>}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          onClose={() => this.hideRedirectionsModal()}
          onAgree={() => this.hideRedirectionsModal()}
        />}
        {showEuMigration && <MigrationToEuPopup />}
        {!showEuMigration && enableCompanyRetailClosurePopup && <CompanyRetailClosurePopup
          open={showCompanyRetailClosurePopup}
          onClose={() => this.hideCompanyRetailClosurePopup()}
        />}
        {!showEuMigration && mifirCondition && <MifirPopup
          open={showMifirPopup}
          onClose={() => this.hideMifirPopup()}
          nationality={nationality}
        />}
        {!showEuMigration && cfdNoticeCondition && <CFDNoticePopup
          noticeId={'generalCFDDisclaimer'}
          open={showCFDNoticePopup}
          onClose={() => this.hideCFDNoticePopup()}
        />}
        {!showEuMigration && showWhiteLabelTerminationPopup && !isEmpty(whiteLabelTerminationObject) && <WhiteLabelTerminationPopup
          open={showWhiteLabelTerminationPopup}
          onClose={() => this.hideWhiteLabelTerminationPopup()}
          whiteLabelTerminationObject={whiteLabelTerminationObject}
        />}
        {showRefreshNotification && <UiNotification
          open={true}
          status={'failure'}
          title={'refresh'}
          subTitle={<Trans {...messages.somethingWrongRefresh} />}
          type="page-not-found"
          buttonMessage={t(messages.refresh.i18nKey, messages.refresh.defaults)}
          onClose={() => window.location.reload()}
        >
          <Typography variant="caption">
            <Trans {...messages.somethingWrongRefreshText} />
          </Typography>
        </UiNotification>}
        {wlRedirection && <AlertDialog
          open={showRedirectionModal}
          title={t(messages.entityRedirectionHeader.i18nKey, messages.entityRedirectionHeader.defaults)}
          children={<Typography variant="body1"> <Trans {...messages.entityRedirectionMessage} values={{currentEntity: name}} /> </Typography>}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          onClose={()=> this.hideRedirectionsModal()}
          onAgree={()=> this.hideRedirectionsModal()}
        />}
        <AlertDialog
          open={showIBPopup}
          onClose={() => this.setState({showIBPopup: false})}
          title={t(messages.welcome.i18nKey, messages.welcome.defaults)}
          agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          onAgree={() => this.setState({showIBPopup: false})}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages.ibAccountNotApproved} /></Typography>
              <Typography variant="body1"><Trans {...messages.ibAccountNotApprovedText2} values={{email: ibPartnersEmail}}/></Typography>
            </Grid>
          </Grid>
        </AlertDialog>
        <CustomDialog
          keepMounted
          disableEscapeKeyDown
          closeBtn={true}
          onCloseIcon={() => this.closeZuluPromo()}
          onClose={() => this.closeZuluPromo()}
          disableEnforceFocus
          disableBackdropClick
          hideBackdrop
          open={showCopyTradePromo}
          title={ <Trans {...messages.copyTradingDialogTitle} />}
          agreeText={t(messages.openAccountCopyTrading.i18nKey, messages.openAccountCopyTrading.defaults)}
          disagreeText={t(messages.learnMore.i18nKey, messages.learnMore.defaults)}
          customClasses={{
            closeIcon: classes.closeIcon,
            dialog: isMobile() ? classes.dialogMobile : classes.dialog
          }}
        >
          <Grid container justifyContent="center" spacing={3}>
            <Grid item>
              <Typography variant="body1" className={classes.copyTradingText}><Trans {...messages.copyTradingDialogSubTitle}/></Typography>
            </Grid>
            <Grid item>
              <div>
                <img className={classes.zuluImage} src={Images[`zuluTrade-image-${themePreference}${isMobileDevice ? '-mobile.': '.'}png`]} alt='zuluTrade' />
              </div>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end" className={classes.buttonGroup}>
              <Grid container direction="row" justifyContent={'flex-end' } alignItems={'flex-end' } spacing={1}>
                <Grid item xs={isMobile() ? 12 : 'auto'}>
                  <LoadingButton
                    disabled={false}
                    id='loadingButton'
                    size="medium"
                    onClick={() => this.copyTradingTradePromoPopupAction('startCopyTrading')}
                    fullWidth={isMobile()}
                    noMargin
                  >
                    <Trans {...messages.openAccountCopyTrading} />
                  </LoadingButton>
                </Grid>
                <Grid item xs={isMobile() ? 12 : 'auto'}>
                  <Button fullWidth={isMobile()} color="secondary" size="medium" variant="contained"
                    onClick={() => this.copyTradingTradePromoPopupAction('learnMore')}>
                    <Trans {...messages.learnMore} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CustomDialog>
        <IbAccountSelectModal
          open={showIbAccountSelectionModal}
          onClose={() => this.setState({showIbAccountSelectionModal: false})}
          account={ibAccountSelected}
          onSelectAccount={(ibId) => this.toIBPortal(ibAccountSelected, ibId)}
        />
        {multipleReglation && <PartnerMigrationPopup client={viewer}/>}
        <AlertDialog
          open={showBitnukPopup}
          onClose={() => this.setState({showBitnukPopup: false})}
          title={t(messages.unlockWallet.i18nKey, {wallet: get(hasBitnuk, 'accountType.localization') && hasBitnuk.accountType.localization.t(locale)})}
          agreeText={t(messages.completeVerification.i18nKey, messages.completeVerification.defaults)}
          disagreeText={t(messages.close.i18nKey, messages.close.defaults)}
          onAgree={() => history.push('/accounts/verification')}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="body1"><Trans {...messages.bitwalletBenefits} values={{company: companyObject.brandLabel}}/></Typography>
            </Grid>
            <ul>
              {map(get(accountCategoriesDetails, 'cryptoWallet.bitnuk.benefits'), (benefit) => <li key={benefit}>
                <Grid item xs={12}>
                  <Typography variant="body1"><Trans {...messages[benefit]}
                    values={{company: companyObject.brandLabel, wallet: get(hasBitnuk, 'accountType.localization') && hasBitnuk.accountType.localization.t(locale)}}/></Typography>
                </Grid>
              </li>)}
            </ul>
          </Grid>
        </AlertDialog>
        {(referralDetails || showReferredSomeone) && showReferral && referredBy && <UiNotification
          open={showReferral}
          title={showReferredSomeone ? 'congratulationsReferredSomeone' : 'congratulationsReferred'}
          titleComponents={[<br key={'br'} />]}
          status={'referral'}
          children={showReferredSomeone ? this.referralNotificationReferredContent(): this.referralNotificationContent(referralDetails)}
          type="register"
          buttonMessage={showReferredSomeone ? t(messages.becomeAnIbButton.i18nKey, messages.becomeAnIbButton.defaults) : t(messages.deposit.i18nKey, messages.deposit.defaults)}
          cancelButton={showReferredSomeone && t(messages.learnMore.i18nKey, messages.learnMore.defaults)}
          onCancel={() => {
            window.open(ibPartnersLink, '_blank')
          }}
          onClose={() => {
            removeItem('referralDetails')
            this.setState({showReferral: false})
            if (showReferredSomeone) {
              this.removeFirstReferral()
            }
          }}
          buttonAction={() => {
            removeItem('referralDetails')
            if (showReferredSomeone) {
              this.becomeAnIb()
            } else {
              history.push('/transactions/deposit')
            }
          }}
        />}
        <AlertDialog
          open={nationalIdRequired && !viewer.nationalIdNumber && depositedAmount > 0}
          title={t(messages.nationalIdNumberPopupTitle.i18nKey, messages.nationalIdNumberPopupTitle.defaults)}
          agreeText={t(messages.proceedButton.i18nKey, messages.proceedButton.defaults)}
          onAgree={() => history.push('/settings/profile/personal-profile/details')}
        >
          <Typography variant="body1"><Trans {...messages.nationalIdNumberPopupMessage}>
            <Link to="/settings/profile/personal-profile/details" className={classes.textLink}>here</Link>
          </Trans></Typography>
        </AlertDialog>
        <PageTitle
          hideArrow
          title={t(messages.accounts.i18nKey, messages.accounts.defaults)}
          rightAction={this.renderLinks(showCompetitions)}
        >
          <Tabs
            value={this.state.activeTab}
            onChange={(_, activeTab) => this.setState({activeTab})}
            classes={{root: classes.tabsRoot}}
            variant={!isMobile() ? 'standard' : 'fullWidth'}
          >
            <Tab label={t(messages.allAccountsTab.i18nKey, messages.allAccountsTab.defaults)}/>
            {_.map(categories, category => {
              const accountTypeDetails = accountCategoriesDetails[category.key]
              const dot = find(keys(accountTypeDetails), (accountType) => {
                const accountTypeEndDate = moment(accountTypeDetails[accountType].startDate).add(accountTypeDetails[accountType].activeDays, 'd')
                return moment(today).isSameOrBefore(moment(accountTypeEndDate).format(), 'day')
              })
              return <Tab key={category.key} label={(dot && hasBitnuk) ?
                <span>{category.localization.t(locale)}<FiberManualRecordIcon className={classes.dot}/></span>
                : category.localization.t(locale)}
              />
            }
            )}
          </Tabs>
        </PageTitle>
        <PageBody>
          <Grid container spacing={isMobile() ? 2 : 3}>
            {showAccountCreatedMessage && <Grid item xs={12}>
              <NotificationBar
                status='success'
                title={<Trans {...messages.createAccountSuccess} />}
                ctaMessage={<Trans {...messages.dismiss} />}
                ctaAction={() => history.push('/')}
              />
            </Grid>}
            {!blockedDeposit && <ClientNotificationBar accounts={accounts} hasBitnuk={hasBitnuk}/>}
            <Grid item xs={12}>
              <Grid container spacing={isMobile() ? 2 : 3}>
                {filteredAccounts.map(account =>
                  <Grid item xs={12} sm={6} key={account.id}>
                    <AccountCard account={account} kycStatus={kycStatus} onClick={account => this.openAccount(account)} viewer={viewer} history={history} localDepositorSubscriptionsCount={localDepositorSubscriptionsCount}/>
                  </Grid>
                )}
              </Grid>
            </Grid>
            {accountLimitReached.length < 1 && isMobile() && <Grid item xs={12} className={classes.maxAccountsDiv}>
              <Typography variant="caption"><Trans {...messages.maximumAccounts} /></Typography>
              <Link to={'/support'} className={classes.textLink}><Trans {...messages.contactUs} /></Link>
            </Grid>}
          </Grid>
          <Footer country={country} viewer={viewer}/>
        </PageBody>
      </React.Fragment>
    )
  }
}

Accounts.propTypes = {
  accounts: PropTypes.array,
  error: PropTypes.object,
  loading: PropTypes.bool,
}


export default compose(
  withApollo,
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => {
      const accounts = filter(get(data, 'viewer.accounts'), (account) => !isPAMMFundManagerAccount(account))
      const availableAccountTypeCategories = _.chain(accounts).map(account =>
        accountTypes[account.__typename].category
      ).uniq().value()
      const categories = _.chain(accountCategories).values().filter(category =>
        _.intersection(category.accountTypeCategories, availableAccountTypeCategories).length > 0
      ).value()
      return {
        error,
        loading,
        accounts,
        categories,
      }
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => {
      const mifirId = get(data, 'viewer.mifirId')
      const mifirType = get(data, 'viewer.mifirType')
      const nationality = get(data, 'viewer.nationality')
      const country = get(data, 'viewer.address.country')
      const depositedAmount = get(data, 'viewer.depositedAmount')
      const registration = get(data, 'viewer.registration')
      const kycStatus = get(data, 'viewer.kycStatus')
      const spanishCfdNotice = get(data, 'viewer.signableNoticeAckSignatures', '')
      const appropTest = get(data, 'viewer.appropTests', '')
      const globalQuestionnaire = get(data, 'viewer.globalQuestionnaire', '')
      const allowedAccountTypes = get(data, 'viewer.allowedAccountTypes' ,'')
      return {
        errorClient: error,
        loadingClient: loading,
        mifirId,
        mifirType,
        nationality,
        country,
        depositedAmount,
        registration,
        kycStatus,
        spanishCfdNotice,
        appropTest,
        globalQuestionnaire,
        allowedAccountTypes,
        viewer: get(data, 'viewer'),
      }
    }
  }),
  graphql(GET_COMPETITIONS_APPLICABLE_COUNT_QUERY, {
    skip: props => !props.viewer,
    options: (props) => ({
      variables: {memberId: props.viewer.id},
    }),
    props: ({data}) => ({
      competitions: get(data, 'competitionsApplicableCount', 0),
    })
  }),
  graphql(CLIENT_REFER_CAMPAIGN_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      errorRefer: error,
      loadingRefer: loading,
      referCampaign: get(data, 'viewer.referCampaign'),
    })
  }),
  graphql(IB_SSO_LINK_MUTATION, {
    name: 'createIBSingleSignOnLink',
  }),
  graphql(IB_INTERESTED_MUTATION, {
    name: 'ibInterested',
  }),
  graphql(ADD_METADATA_TO_CLIENT_MUTATION, {
    name: 'addMetadataToClient',
    options: () => ({
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    }),
  }),
  graphql(REFERRED_BY_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      errorReferred: error,
      loadingReferred: loading,
      referredBy: get(data, 'viewer.referredBy'),
    })
  }),
  graphql(LOCAL_DEPOSITOR_DETAILS, {
    props: ({data: {error, loading}, data}) => ({
      errorLocalDepositor: error,
      loadingLocalDepositor: loading,
      localDepositorSubscriptionsCount: get(data, 'viewer.localDepositorSubscriptionsCount'),
    })
  })
)(Accounts)
