import React, {Component} from 'react'
import {includes, get, find, filter, map, upperCase} from 'lodash'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import {withNamespaces, Trans} from 'react-i18next'
import PropTypes from 'prop-types'
import {accountSubtypes, accountTypes, kycStatuses, leverageTypes, mt4Servers, whiteLabels, mt5Servers} from '@bdswiss/common-enums'
import Amount from '../Common/Amount'
import classNames from 'classnames'
import {isMAMScSubAccount, isAffiliatesAccount, isForexAccount, getForcedServerName, getAccountSubtype,
  getAccountLabel, isBitnukAccount, isCentAccount} from '../../common/utils/accounts'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import {isMobile} from '../../common/utils/browser'
import {isWhiteLabel, convertToCamelCaseAndRemoveUnderscores, isMenaCountry, isCopyTradingAccount} from '../../common/utils'
import config from '../../config'
import {accountCategoriesDetails, serversLabel} from '../../common/utils/uioptions'
import moment from 'moment'
import CustomTooltip from '../Common/CustomTooltip'
import HelpOutline from '@mui/icons-material/HelpOutline'
import {ReactComponent as SwapFreeIcon} from '../../assets/images/icon-swapFreeAccount.svg'

const styles = theme => ({
  cardRoot: {
    cursor: 'pointer',
    position:'relative',
    height: '100%',
    boxShadow: theme.boxShadowStyle,
    padding: 24,
    [theme.breakpoints.down('md')]: {
      padding: 16,
    },
  },
  amount: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 55,
    [theme.breakpoints.down('md')]: {
      [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: 45,
    },
  },
  ldDisplay: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  amountCurrency: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    padding: '0 3px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      letterSpacing: '0.5px',
    },
  },
  amountValue: {
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      letterSpacing: '0.5px',
    },
  },
  amountFraction: {
    fontSize: 18,
    fontWeight: 300,
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: theme.palette.grey.color,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  amountMobile:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      fontWeight: 400,
      fontStyle: 'normal',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
  },
  typeBadge: {
    height: 40,
    width: 40,
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: '0.1px',
    lineHeight: '40px',
    borderRadius: 20,
    textTransform: 'uppercase',
    textAlign: 'center',
    [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 18,
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
    [theme.breakpoints.down('sm')]: {
      width: 35,
      height: 35,
      lineHeight: '35px',
      [theme.direction === 'rtl' ? 'marginLeft' : 'marginRight']: 14,
      fontSize: 12,
      fontStyle: 'normal',
      fontWeight: 400,
      letterSpacing: '0.4px',
    },
  },
  forexMt4: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  forexMt5: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  cent: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  centMt5: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  pammForex: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  spotOption: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  tradeSmarter: {
    color: theme.palette.green.color,
    backgroundColor: theme.palette.lightgreen.color,
  },
  default: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.lightblue.color,
  },
  affiliate: {
    color: theme.palette.mode === 'dark' ? '#38136A' : theme.palette.purple.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#A967FD' : theme.palette.lightpurple.color,
  },
  copy_vip: {
    color: theme.palette.yellow.color,
    backgroundColor: theme.palette.lightyellow.color,
  },
  black_zulu: {
    color: theme.palette.yellow.color,
    backgroundColor: theme.palette.lightyellow.color,
  },
  zero_zulu: {
    color: theme.palette.yellow.color,
    backgroundColor: theme.palette.lightyellow.color,
  },
  copy_zero: {
    color: theme.palette.yellow.color,
    backgroundColor: theme.palette.lightyellow.color,
  },
  cent_zulu: {
    color: theme.palette.yellow.color,
    backgroundColor: theme.palette.lightyellow.color,
  },
  copy_cent: {
    color: theme.palette.yellow.color,
    backgroundColor: theme.palette.lightyellow.color,
  },
  basic_zulu: {
    color: theme.palette.yellow.color,
    backgroundColor: theme.palette.lightyellow.color,
  },
  copy_classic: {
    color: theme.palette.yellow.color,
    backgroundColor: theme.palette.lightyellow.color,
  },
  standard: {
    color: theme.palette.type === 'dark' ? '#419CFF' : '#007AFF',
    backgroundColor:  `${get(theme.palette, 'main.color') || theme.palette.lightblue.color}`,
  },
  prime: {
    color: theme.palette.cyan.color,
    backgroundColor:  theme.palette.lightcyan.color,
  },
  pro: {
    color: theme.palette.pink.color,
    backgroundColor:  theme.palette.lightpink.color,
  },
  swapfree: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  ldWallet: {
    color: theme.palette.mode === 'dark' ? '#C2C2C2' : '#707070',
    backgroundColor: theme.palette.mode === 'dark' ? '#474747' : '#EBEBEB',
  },
  isLiveText: {
    opacity: 1,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: '4px',
    color: theme.palette.mode === 'dark' ? '#EE8A00' : '#F87700',
    backgroundColor: theme.palette.mode === 'dark' ? '#463313' : '#FFEFD5',
  },
  link: {
    color: theme.palette.primary.main,
  },
  swapfreeImage: {
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
    width: '17px',
    height: '17px',
    marginTop: '11px',
    '& path': {
      fill: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    },
  },
  swapfreeImageGrey: {
    color: theme.palette.mode === 'dark' ? '#C2C2C2' : '#707070',
    backgroundColor: theme.palette.mode === 'dark' ? '#474747' : '#EBEBEB',
    width: '17px',
    height: '17px',
    marginTop: '11px',
    '& path': {
      fill: theme.palette.mode === 'dark' ? '#C2C2C2' : '#707070',
    },
  },
  swapfreeImagePink: {
    backgroundColor: theme.palette.mode === 'dark' ? '#491B49' : theme.palette.lightpink.color,
    width: '17px',
    height: '17px',
    marginTop: '11px',
    '& path': {
      fill: theme.palette.mode === 'dark' ? '#E55CE5' : theme.palette.pink.color,
    },
  },
  cardContent:{
    flexGrow:1,
    [theme.breakpoints.up('sm')]: {
      minHeight: 80,
    },
  },
  isDemoArchived:{
    borderRadius: '4px',
    height: 22,
    [theme.breakpoints.down('sm')]: {
      height: 16,
      padding: 0,
    },
  },
  badgesBox:{
    [theme.direction === 'rtl' ? 'left' : 'right']: 16,
    top: 16,
    position: 'absolute',
    [theme.direction === 'rtl' ? 'left' : 'right']: 8,
    display: 'flex',
    padding: '0px 8px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '10px',
    borderRadius: 4,
    flexDirection: 'row-reverse',
    [theme.breakpoints.down('sm')]: {
      top: 8,
      [theme.direction === 'rtl' ? 'left' : 'right']: 8,
      padding: 0,
    },
  },
  isDemo:{
    color: theme.palette.mode === 'dark' ? '#C2C2C2' : '#707070',
    backgroundColor: theme.palette.mode === 'dark' ? '#474747' : '#EBEBEB',
  },
  isDemoText:{
    opacity: 1,
    paddingLeft: 6,
    paddingRight: 6,
    borderRadius: '4px',
    color: theme.palette.mode === 'dark' ? '#C2C2C2' : '#707070',
  },
  isArchived:{
    backgroundColor:  theme.palette.lightred.color,
  },
  isArchivedText:{
    color: theme.palette.red.color,
  },
  ib: {
    color: theme.palette.mode === 'dark' ? '#A967FD' : theme.palette.purple.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#38136A' : theme.palette.lightpurple.color,
  },
  basic: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : '#007AFF',
    backgroundColor:  `${get(theme.palette, 'main.color') || theme.palette.lightblue.color}`,
  },
  raw: {
    color: theme.palette.mode === 'dark' ? '#E55CE5': theme.palette.pink.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#491B49' : theme.palette.lightpink.color,
  },
  zero: {
    color: theme.palette.mode === 'dark' ? '#E55CE5' : theme.palette.pink.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#491B49' : theme.palette.lightpink.color,
  },
  black: {
    color: theme.palette.mode === 'dark' ? '#E55CE5': theme.palette.pink.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#491B49' : theme.palette.lightpink.color,
  },
  invest_plus: {
    color: theme.palette.mode === 'dark' ? '#419CFF' : theme.palette.primary.main,
    backgroundColor: theme.palette.mode === 'dark' ? '#0C3059' : theme.palette.lightblue.color,
  },
  copyingText:{
    color:  theme.palette.mode === 'dark' ? '#FC4950' : '#ED1C24',
    backgroundColor:  theme.palette.mode === 'dark' ? '#491B1B' : '#FFEDEE',
  },
  competitionDiv:{
    backgroundColor:  theme.palette.green.color,
  },
  competitionText:{
    color: theme.palette.green.textColor,
  },
  providing: {
    color: theme.palette.primary.main,
  },
  bonusDiv:{
    backgroundColor: theme.palette.cyan.color,
  },
  bonusText:{
    fontWeight: 500
  },
  premium: {
    color: theme.palette.mode === 'dark' ? '#E55CE5' : theme.palette.pink.color,
    backgroundColor: theme.palette.mode === 'dark' ? '#491B49' : theme.palette.lightpink.color,
  },
  badgeWrapper: {
    paddingRight: 6,
    paddingLeft: 6,
    borderRadius: '4px',
    height: '22px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 16,
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center'
    },
  },
  pendingApproval: {
    color: theme.palette.mode === 'dark' ? '#C2C2C2' : '#707070',
  },
  labelText: {
    fontStyle: 'normal',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    }
  },
  captionText: {
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    letterSpacing: '0.25px',
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      letterSpacing: '0.4px',
      lineHeight: '16px',
    }
  },
  copyingBg: {
    backgroundColor: theme.palette.mode === 'dark' ? '#491B1B' : '#FFEDEE',
  },
  copyingTxt: {
    color: theme.palette.mode === 'dark' ? '#FC4950' : '#ED1C24',
  },
  tooltipHelpIcon: {
    marginLeft: 4,
    marginTop: 4,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 4,
      marginTop: 4,
    },
    fontSize:14
  },
  textHelp: {
    color: theme.palette.grey[400],
    '&:hover':{
      color: theme.palette.secondary.light,
    },
  },
  tooltipText:{
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  customTooltip: {
    backgroundColor: theme.palette.primary.main,
  },
  arrowStyle: {
    color: '#ffffff',
  },
  highlightText:{
    fontWeight:400
  },
})

class AccountCard extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      fulldialogOpen: false,
    }
  }

  _onClickLD(event) {
    const {history} = this.props
    event.stopPropagation()
    history.push('/transactions/local-depositor-transfer')
  }

  addLeverageType (account, caption) {
    const {t} = this.props
    const leverageType = get(account, 'leverageType') || 'default_leverage'
    const leverage = convertToCamelCaseAndRemoveUnderscores(leverageType)
    return `${caption} · ${t(messages[leverage]?.i18nKey, messages[leverage]?.defaults)}`
  }

  render() {
    const {classes, account, onClick, t, kycStatus, viewer, localDepositorSubscriptionsCount} = this.props
    const {downloadTrader, key, hideMt4Mentions} = config
    const accountType = accountTypes[account.__typename]
    const accountSubtype = getAccountSubtype(account.accountSubtype)
    const category = get(accountTypes[account.__typename], 'category')
    const serverLabel = (hideMt4Mentions) ? '' : `${serversLabel[category]} · `
    let caption = account.isDemo ? `${serverLabel}${account.remoteId}` :
      accountSubtype && !isMAMScSubAccount(account) ? `${serverLabel}${account.remoteId}` :
        isBitnukAccount(account) ? account.login : account.remoteId
    const {locale, companyObject, company} = this.context
    // SubTypes for Mam account are not translated
    const label = isMAMScSubAccount(account) ? accountSubtype.label : getAccountLabel(account, locale, accountSubtype, t, get(viewer, 'address.country'))
    let classesCategory = ((account.isDemo || account.isViewOnly || account.isReadOnly) && classes.isDemo) || classes[accountType.subCategory] ||(!account.isDemo && classes[account.accountSubtype || get(account, 'accountType.category')])
    || classes[accountType.category]
    let shortLabel = (accountType.subCategory && accountType.shortLabel) || (account.accountSubtype
      && get(accountSubtype, 'shortLabel')) || accountType.shortLabel || account.currency
    if (account.swapFree && (account.isViewOnly || account.isReadOnly)) {
      classesCategory = classes.isDemo
      shortLabel = <SwapFreeIcon className={classes.swapfreeImageGrey} />
    }
    else if (account.swapFree && !account.isDemo && (account.accountSubtype === 'black' || account.accountSubtype === 'zero' ||
      account.accountSubtype === 'raw')) {
      shortLabel = <SwapFreeIcon className={classes.swapfreeImagePink} />
    } else if (account.swapFree) {
      classesCategory = classes.swapfree
      shortLabel = <SwapFreeIcon className={classes.swapfreeImage} />
    }
    const verifiedAffiliate = (isAffiliatesAccount(account) && includes(['Approved'],
      get(account, 'serviceFields.affiliateStatus')))
    const traderConfig = accountTypes[account.__typename] ? downloadTrader[accountTypes[account.__typename].category] : downloadTrader.default
    const missingVerificationsAffiliate = (isAffiliatesAccount(account) && kycStatus !== kycStatuses.approved.value)
    const copyTradingEnabled = get(account.copyTrading,'isActive')
    const hasActiveCompetition = account.competitions && find(account.competitions, 'active')
    const provideCopyTrading =  get(account, 'provideCopyTrading')
    const hasActiveBonus = !!account.credit
    const {forcedServerName} = getForcedServerName(account)
    const whiteLabel = isWhiteLabel()
    const today = moment().format()
    const showBitnukDetails = accountCategoriesDetails['cryptoWallet'].bitnuk
    const bitnukEndDate = moment(showBitnukDetails.startDate).add(showBitnukDetails.activeDays, 'd')
    if (get(account, 'accountType.category') === 'ldWallet') {
      caption =localDepositorSubscriptionsCount > 0 ? <Trans {...messages.localDepositorSubscriptions} values={{localDepositorSubscriptionsCount: localDepositorSubscriptionsCount}}/> : ''
    }
    const showLDbtn = get(account, 'accountType.category') === 'ldWallet' && get(viewer, 'isLocalDepositor')
    const accountSubTypesDynamicLeverage = map(filter(accountSubtypes, (a) => {
      const upperCaseCountry = upperCase(get(viewer, 'address.country'))
      const isDynamic = get(account, 'leverageType') === leverageTypes.dynamicLeverage.value
      const specialGroup = a.specialGroup && a.specialGroup(upperCaseCountry, isDynamic)

      return specialGroup?.[company]?.isDynamic
    }), 'key')

    if (includes(accountSubTypesDynamicLeverage, get(account, 'accountSubType')) && !isMenaCountry(upperCase(get(viewer, 'address.country')))) {
      caption = this.addLeverageType(account, caption)
    }
    return <Card classes={{root: classes.cardRoot}} onClick={() => onClick && onClick(account)}>
      <Grid container>
        <Grid item>
          <div className={classNames(classes.typeBadge, classesCategory)}>
            {shortLabel}</div>
        </Grid>
        <Grid item className={classes.cardContent}>
          <Typography variant='subtitle1' className={classes.labelText}>{label}</Typography>
          {isMobile() &&  <div className={classes.amountMobile}>
            <Typography variant='caption' className={classes.captionText}>{caption}</Typography></div>}
          {!isMobile() && <Typography variant='caption' className={classes.captionText}>{caption}</Typography>}
          {account.isArchived && <Typography variant='caption'><Trans {...messages.archivedMessage} />
          </Typography>}
          {(isForexAccount(account) || isCentAccount(account)) && <Typography variant='caption' className={classes.captionText}>
            {forcedServerName || (
              (account.server && (!whiteLabel|| (whiteLabel && get(whiteLabels[key], 'internal'))))
                ? (get(mt4Servers[account.server], `name[${companyObject['key']}]`) || get(mt5Servers, [account.server, 'name', companyObject['key']], ''))
                : (account.isDemo
                  ? traderConfig.serverName.demo
                  : whiteLabel && !get(whiteLabels[key], 'internal')
                    ? get(traderConfig.serverName, account.server)
                    : traderConfig.serverName.real)
            )}
          </Typography>
          }
          {showLDbtn &&
            <Button color={'primary'} style={{backgroundColor: 'transparent', padding:0}} onClick={(e)=> this._onClickLD(e)}>
              <Trans {...messages.ldTransfer} /></Button>}
        </Grid>
        <Grid item className={classes.badgesBox}>
          {accountCategoriesDetails[accountType.category] && get(accountCategoriesDetails[accountType.category][accountType.subCategory], 'newBadge')
            && moment(today).isSameOrBefore(moment(bitnukEndDate).format(), 'day') && !account.balance
            && !account.isArchived && !account.isReadOnly && !account.isViewOnly && !copyTradingEnabled &&
            <Grid item>
              <Typography variant='caption' className={classNames(classesCategory, classes.badgeWrapper)}><Trans {...messages.new} /></Typography>
            </Grid>
          }
          {!account.isArchived && account.isReadOnly && !account.isViewOnly &&
          <Grid item>
            <Typography variant='caption' className={classNames(classesCategory, classes.badgeWrapper)}>
              <Trans {...messages.readOnlyLowerCase} />
              {isCopyTradingAccount(account) && <CustomTooltip
                hideArrow={false}
                tooltipLight={true}
                arrowLight={true}
                title={<Typography variant='caption' className={classes.tooltipText}>
                  <Trans
                    {...messages.notActivatedZuluAccountTooltip}
                    values={{
                      zuluAccountType: accountSubtypes[account?.accountSubtype].label,
                      activationFtd: (accountSubtypes[account?.accountSubtype].activationFtd).toFixed(2),
                    }}
                    components={[
                      <span className={classes.highlightText}>zuluAccountType</span>,
                      <span className={classes.highlightText}>activationFtd</span>
                    ]}
                  />
                </Typography>}
                placement={'bottom-end'}
                className={classNames(classes.customTooltip, classes.toolTipPartner)}
              >
                <HelpOutline className={classNames(classes.tooltipHelpIcon, classes.textHelp)} />
              </CustomTooltip>}
            </Typography>
          </Grid>
          }
          {!account.isArchived && account.isViewOnly && <Grid item className={classNames(classes.isDemoArchived, classes.isDemo)}>
            <Typography variant='caption' className={classNames(classes.isDemoText, classes.badgeWrapper)}><Trans {...messages.viewOnly} /></Typography>
          </Grid>
          }
          {accountType.isDemo && <Grid item className={classNames(classes.isDemoArchived, classes.isDemo)}>
            <Typography variant='caption' className={classNames(classes.isDemoText, classes.badgeWrapper)}><Trans {...messages.demo} /></Typography>
          </Grid>
          }
          {account.isArchived && <Grid item>
            <Tooltip title={t(messages.archivedTooltip.i18nKey, messages.archivedTooltip.defaults)} placement="top">
              <Typography variant='caption' className={classNames(classesCategory, classes.badgeWrapper)}>
                <Trans {...messages.archived} /></Typography>
            </Tooltip>
          </Grid>
          }
          {isAffiliatesAccount(account) && <Grid item>
            <Typography variant='caption' className={classNames(classesCategory, classes.badgeWrapper)}>
              {!verifiedAffiliate ? <Trans {...messages.pendingApproval} /> : ''}
              {verifiedAffiliate && missingVerificationsAffiliate ? <Trans {...messages.notVerified} /> : ''}
            </Typography>
          </Grid>
          }
          {hasActiveCompetition && !account.isArchived && !account.isReadOnly && !account.isViewOnly && !copyTradingEnabled &&
          <Grid item>
            <Typography variant='caption' className={classNames(classesCategory, classes.badgeWrapper)}><Trans {...messages.competition} /></Typography>
          </Grid>
          }
          {account.isTripleZero &&
            <Grid item>
              <Typography variant='caption' className={classNames(classesCategory, classes.badgeWrapper)}><Trans {...messages.tripleZero} /></Typography>
            </Grid>
          }
          {provideCopyTrading &&
            <Grid item>
              <Typography variant='caption' className={classNames(classesCategory, classes.badgeWrapper)}><Trans {...messages.providing} /></Typography>
            </Grid>
          }
          {isForexAccount(account) && copyTradingEnabled && <Grid item>
            <Typography variant='caption' className={classNames(classesCategory, classes.badgeWrapper)}><Trans {...messages.copying} /></Typography>
          </Grid>
          }
          {hasActiveBonus && !account.isArchived && !account.isReadOnly && !account.isViewOnly && !copyTradingEnabled &&
            <Grid item>
              <Typography variant='caption' className={classNames(classesCategory, classes.badgeWrapper)}><Trans {...messages.bonus} /></Typography>
            </Grid>
          }
          {!account.isArchived && !account.isReadOnly && !account.isViewOnly && !accountType.isDemo &&
            <Grid item>
              <Typography variant='caption' className={classNames(classes.badgeWrapper, classesCategory)}><Trans {...messages.live} /></Typography>
            </Grid>
          }
        </Grid>
      </Grid>
      {<Typography variant='body2' className={classes.amount}>
        <Amount value={account.balance} currency={account.currency} locale='en-US' classes={{
          currency: classes.amountCurrency,
          value: classes.amountValue,
          fraction: classes.amountFraction
        }} />
      </Typography>
      }
    </Card>
  }
}

AccountCard.propTypes = {
  account: PropTypes.object,
  locale: PropTypes.string,
  onClick: PropTypes.func,
}


export default withStyles(styles, {withTheme: true})(withNamespaces()(AccountCard))
