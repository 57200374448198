import React from 'react'
import {get, flowRight as compose, includes, toString} from 'lodash'
import {graphql} from 'react-apollo'
import {withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {withRouter} from 'react-router-dom'
import {PROMOTIONS_QUERY} from '../../graphql/queries'
import {Grid} from '@mui/material'
import PromotionOptInCard from './PromotionOptInCard'
import PromotionsContainer from './PromotionsContainer'
import {isMobile} from '../../common/utils'
import {isIos} from '../../common/utils/browser'
import Loading from '../Common/Loading'
import config from '../../config'

// The CSS property -webkit-overflow-scrolling: 'touch' creates a new stacking context
// on iOS devices which is consider to be a bug for some elements that uses z-index property
const styles = theme => ({
  root: {
    '-webkit-overflow-scrolling': isIos() ? 'auto' : 'touch',
    [theme.breakpoints.down('sm')]: {
      minWidth: '363px',
    }
  },
  noPadding: {
    '&.MuiGrid-item': {
      padding: '0px',
    }
  },
  noRightPadding: {
    '&.MuiGrid-item': {
      paddingRight: '0px',
    }
  }
})

class Promotions extends React.Component {

  render() {
    const {classes, viewer, loading, history} = this.props
    if (loading || !viewer) return (<Loading />)
    const {promotionsEnabled, optInPromotions, promotionsEligibility, id} = viewer
    const {common:{plugitAllowedClientIds}} = config

    const showPlugit = includes(plugitAllowedClientIds, toString(id))

    if (!promotionsEligibility || !showPlugit) history.push('/')
    const isMobileDevice = isMobile()

    return (
      <Grid container direction='column' spacing={3} className={classes.root}>
        {!isMobile() && <Grid item xs={12} sm={12} className={isMobileDevice ? classes.noPadding : ''}>
          <PromotionOptInCard
            optInPromotionsValue={optInPromotions}
            promotionsEnabled={promotionsEnabled}/>
        </Grid>}
        <Grid item xs={12} sm={12} className={isMobileDevice ? classes.noPadding : classes.noRightPadding}>
          <PromotionsContainer
            optInPromotionsValue={optInPromotions}
            promotionsEnabled={promotionsEnabled}/>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  withRouter,
  graphql(PROMOTIONS_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      viewer: get(data, 'viewer'),
    })
  }),
)(Promotions)
