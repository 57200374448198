import React from 'react'
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'

const bugsnagClient = bugsnag({
  apiKey: process.env.REACT_APP_BD_BUGSNAG_API_KEY,
  notifyReleaseStages: ['production'],
  releaseStage: process.env.NODE_ENV,
  appVersion: process.env.BD_APP_VERSION,
  filters: [
    'authtoken',
    /^password$/i,
  ],
  logger: null,
})

bugsnagClient.use(bugsnagReact, React)

export const BugsnagErrorBoundary = bugsnagClient.getPlugin('react')

export default bugsnagClient
