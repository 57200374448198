import React from 'react'
import {Link} from 'react-router-dom'
import {graphql} from 'react-apollo'
import withStyles from '@mui/styles/withStyles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import Icon from '@mui/material/Icon'
import {withNamespaces, Trans} from 'react-i18next'
import {get, map, filter, keys, includes, flowRight as compose, isNil, size, isEmpty, omit, first, some} from 'lodash'
import messages from '../../../assets/messages'
import {FOREX_ACCOUNT_SETTINGS, CLIENT_DATA_QUERY} from '../../../graphql/queries'
import {UPDATE_LEVERAGE, UPDATE_ACCOUNT_NAME_MUTATION, UPDATE_OWN_PERFORMANCE_FEE_MUTATION, PROVIDE_COPY_TRADING_MUTATION,
  TOGGLE_PASSWORD_STRATEGY_MUTATION, SIGN_UPLOAD_URL_MUTATION, UPDATE_STRATEGY_AVATAR_MUTATION, CHANGE_ACCOUNT_PASSWORD_MUTATION} from '../../../graphql/mutations'
import Typography from '@mui/material/Typography'
import config from '../../../config'
import Loading from '../../Common/Loading'
import LoadingButton from '../../Common/LoadingButton'
import {AlertDialog} from '../../Common/Dialog'
import FormHelperText from '@mui/material/FormHelperText'
import classNames from 'classnames'
import InputAdornment from '@mui/material/InputAdornment'
import Input from '@mui/material/Input'
import {documentStatuses} from '@bdswiss/common-enums'
import {validateLength, validateAccountName, checkFileMimeType} from '../../../common/utils/validations'
import {performanceFeeOptions} from '../../../common/utils/uioptions'
import SwitchButton from '../../Common/SwitchButton'
import {checkAccountCopyTrading, isIBClient, isForexAccount, isDemoAccount, isVipOrRaw, getAccountLabel, getAccountSubtype} from '../../../common/utils/accounts'
import NotificationBar from '../../Common/NotificationBar'
import Password from '../../Common/Password'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import AppContext from '../../Common/contexts/AppContext'
import SelectDocument from '../../Settings/Documents/SelectDocument'
import Images from '../../Common/Images'
import {putFile, checkPassword} from '../../../common/utils'
import Jdenticon from 'react-jdenticon'
import {acceptedImageFormats, maxFileSize} from '../../../common/utils/variables'
import {logEventCustomParams} from '../../../common/utils/firebaseEvents'
import {withMediaQuery} from '../../Common'

const styles = theme => ({
  spinner: {
    textAlign: 'center'
  },
  warningIcon: {
    color: theme.palette.yellow.color,
    verticalAlign: 'sub',
    marginRight: '2px'
  },
  link: {
    color: theme.palette.primary.main,
  },
  highlightText:{
    fontWeight:400
  },
  dialogTitle:{
    textAlign: 'center'
  },
  title: {
    marginTop: 0
  },
  underline: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
    paddingTop: 10,
  },
  switchLabel:{
    display:'inline-block',
  },
  switch:{
    float:'right',
  },
  formControlLabel:{
    float:'left',
    color: theme.palette.grey.color,
  },
  labelPlacementStart:{
    marginLeft: 0,
    marginRight: 0
  },
  div:{
    width: '100%'
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
  alertTitle: {
    fontSize: 24
  },
  rootLoading:{
    color: theme.palette.primary.main,
    '&.success': {
      backgroundColor: 'transparent',
      color: theme.palette.green.color,
    },
    '&.failure': {
      backgroundColor: 'transparent',
      color: theme.palette.error.main,
    },
  },
  noticeDiv: {
    marginBottom: 15,
    padding: 20
  },
  notice: {
    backgroundColor: theme.palette.lightyellow.color,
  },
  info: {
    backgroundColor: theme.palette.lightblue.color,
  },
  noticeTitle: {
    paddingBottom: 15
  },
  list: {
    padding: '0px 0px 0px 20px',
    margin: 0,
  },
  notificationBox: {
    marginTop: 20,
    marginBottom: 0
  },
  grid: {
    paddingBottom: 40
  },
  infoIcon: {
    marginBottom: -6
  },
  financialInfoItem: {
    marginTop: 0,
    textAlign: 'left',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  main: {
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 20
    },
  },
  avatarPendingMessage: {
    color: theme.palette.error.main,
    textAlign: 'center',
  },
  uploadDiv: {
    marginTop: 20
  },
  passwordmessage: {
    marginTop: '8px',
  }
})

class ForexAccountSettings extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      leverageUpdating: false,
      showPopup: false,
      leverageChange: 0,
      form: {password: ''},
      errors: {},
      showFeePopup: false,
      showStopProvidingPopup: false,
      stopProvidingLoading: false,
      showPassword: false,
      uploadedFile: '',
      errorFiles: {},
      uploadLoading: false,
      showNewPassword: false,
      updateAccountPassword: '',
      passwordLoading: false,
      updatePasswordStatus: '',
      submitMessageError: '',
    }
  }

  componentDidMount() {
    logEventCustomParams('viewAccountSettings', {
      userId: get(this.props, 'viewer.id')
    })
  }

  async onLeverageChange() {
    const {leverageChange} = this.state
    const {account} = this.props
    this.setState({leverageUpdating: true})
    await this.props.updateLeverage({variables: {leverage: leverageChange, accountId: this.props.accountId}})
      .then((res) => {
        this.setState({leverageUpdating: false, showPopup: false, changeStatus: 'success', updateOwnLeverage: get(res, 'data.updateOwnLeverage')})
        isDemoAccount(account)
          ? logEventCustomParams('updateDemoLeverage', {userId: get(this.props, 'viewer.id')})
          : logEventCustomParams('updateLeverage', {userId: get(this.props, 'viewer.id')})
      })
      .catch(() => {
        this.setState({leverageUpdating: false, changeStatus: 'failure'})
      })
  }

  async onFeeChange() {
    const {feeChange} = this.state
    this.setState({feeUpdating: true})
    await this.props.updateOwnPerformanceFee({variables: {accountId: this.props.accountId, performanceFee: feeChange}})
      .then(() => {
        this.setState({feeUpdating: false, showFeePopup: false, changeFeeStatus: 'success'})
      })
      .catch(() => {
        this.setState({feeUpdating: false, changeFeeStatus: 'failure'})
      })
  }

  handleChange(name, value) {
    this.setState(state => ({
      nameStatus: includes(name, 'name') ? '' : state.nameStatus,
      form: {...state.form, [name]: value},
      errors: {...state.errors, [name]: !value,},
    }))
  }

  handleChangeAccountPassword(prop, event) {
    const {errors} = this.state
    this.setState({
      [prop]: event.target.value,
      errors: {
        ...errors,
        [prop]: !event.target.value,
      }},this.checkForm)
  }

  handleSubmit =() => {
    const {updateAccountPassword} = this.state
    const errors = {}
    const {t, accountId} = this.props
    const {userId}= this.context
    const newPassword= updateAccountPassword
    this.setState({submitMessageError:''})
    if (isEmpty(updateAccountPassword))
      errors.updateAccountPassword = true
    else if (some(errors)) {
      this.setState({errors})
      return
    }
    else {

      this.props.changeAccountPasswordMutation({
        variables: {accountId, newPassword}
      }).then ((succ) => {
        logEventCustomParams('changeAccountPasswordCompleted', {userId: userId})
        this.setState({passwordLoading: false, updatePasswordStatus: 'success',submitMessageError: ''})
      }).catch((err) => {
        if (err.networkError) {
          this.setState({loading: false, updatePasswordStatus: 'failure',
            submitMessageError: t(messages.networkError.i18nKey, messages.networkError.defaults),
          })
        } else {
          this.setState({loading: false, updatePasswordStatus: 'failure',
            submitMessageError: get( err, 'graphQLErrors[0].message') || err.message,
          })
        }
      })
    }
  }

  checkPassword(password) {
    const {updateAccountPassword} = this.state
    if (password) {
      const passwordError = checkPassword(updateAccountPassword)
      if (isEmpty(passwordError)) {
        this.setState({passwordError: ''})
      } else {
        this.setState({passwordError: <Trans {...messages[passwordError]} />})
      }
    }
  }

  updateName() {
    const {form: {name}} = this.state
    const {accountId, viewer} = this.props
    let nameError = !name || validateAccountName(name)
    if (!nameError) nameError = !validateLength(name, 20) && 'maxLengthError'
    if (nameError) {
      this.setState(state => ({errors: {...state.errors, name: nameError}}))
      logEventCustomParams('updateAccountNameError', {
        reason: nameError,
        userId: get(viewer, 'id'),
      })
      return
    }
    this.setState({nameLoading: true})
    this.props.updateAccountName({variables: {accountId, name}})
      .then((succ) => {
        this.setState({nameLoading: false, nameStatus: 'success'})
        logEventCustomParams('updateAccountName', {
          userId: get(this.props, 'viewer.id'),
        })
      })
      .catch((e) => {
        this.setState({nameLoading: false, nameStatus: 'failure'})
        logEventCustomParams('updateAccountNameError', {
          reason: e,
          userId: get(this.props, 'viewer.id'),
        })
      })
  }

  toggleProvideCopyTrading(provideCopyTrading) {
    const {showStopProvidingPopup} = this.state
    if (!provideCopyTrading && !showStopProvidingPopup)
      this.setState({showStopProvidingPopup: true})
    else {
      const {accountId} = this.props
      this.setState({stopProvidingLoading: true})
      this.props.toggleProvideCopyTrading({variables: {accountId, provideCopyTrading}})
        .then((succ) => {
          this.setState({
            showStopProvidingPopup: false,
            toggleStatus: 'success',
            stopProvidingLoading: false,
            providingErrorMessage: '',
          })
        })
        .catch((e) => {
          this.setState({
            showStopProvidingPopup: false,
            toggleStatus: 'failure',
            stopProvidingLoading: false,
            providingErrorMessage: includes(e.message, 'GraphQL error: ')
              ? e.message.replace('GraphQL error: ', '')
              : this.props.t(messages.generalError.i18nKey, messages.generalError.defaults)
          })
        })
    }
  }

  toggleProtectPassword() {
    const {form: {password}} = this.state
    const {account: {id, passwordProtectedStrategies}} = this.props
    this.setState({protectPasswordLoading: true, protectPasswordToggle: !passwordProtectedStrategies})
    const variables = {accountId: id, password: (!passwordProtectedStrategies) ? password : null}
    this.props.togglePasswordStrategy({variables}).then(() => {
      this.setState(state => ({protectPasswordStatus: 'success', protectPasswordLoading: false, form: {...state.form, password: ''}}))
    }).catch(() => {
      this.setState(state => ({protectPasswordStatus: 'failure', protectPasswordLoading: false, errors: {...state.errors, password: true}}))
    })
  }

  handleClickShowPassword() {
    this.setState(state => ({
      showPassword: !state.showPassword,
      protectPasswordToggle: false,
    }))
  }
  handleClickShowUpdatePassword(prop) {
    this.setState(state => ({[prop]: !state[prop]}))
  }

  handleFileUpload(prop, e) {
    this.setState({[prop]: e.target.files || e.dataTransfer.files, fileChecked: false})
    const {errorFiles} = this.state
    map(e.target.files,(file) => {
      try {
        checkFileMimeType(file).then( res => {
          this.setState({fileChecked: true})
          const fileFormat = res && res.indexOf('image') === -1
          const fileSize = file.size > maxFileSize * 1024 * 1024
          if (fileFormat || fileSize) {
            this.setState(prevState => ({
              errorFiles: {
                ...prevState.errorFiles,
                [prop]: {
                  name: file.name,
                  error: fileFormat ? 'format' : 'size'
                }
              }
            }))
          }
          else {
            const errors = omit(errorFiles,[prop])
            this.setState({errorFiles:errors})
          }
        })
      } catch (e) {
        console.log(e) /* eslint-disable-line */
      }
    })
  }

  uploadStrategyAvatar() {
    const {uploadedFile, errorFiles} = this.state
    this.setState({uploadLoading: true})
    if (!first(uploadedFile) || !isEmpty(errorFiles)) return Promise.resolve()
    const {viewer, signUploadUrl, updateStrategyAvatar, account} = this.props
    const clientId = get(viewer, 'id')
    return signUploadUrl({variables: {clientId: clientId}}).then((res) => {
      const {signedUrl, plainUrl} = res.data.signedDetails
      return putFile(first(uploadedFile), signedUrl).then((res) =>
        updateStrategyAvatar({variables: {accountId: get(account, 'id'), avatarImage: plainUrl}})
          .then((succ) => {
            this.setState({uploadLoading: false, uploadStatus: 'success', uploadedFile: ''})
          })
          .catch((e) => {
            this.setState({uploadLoading: false, uploadStatus: 'failure', uploadedFile: ''})
            console.log(e) //eslint-disable-line no-console
          })
      )
    })
  }
  jdenticon (accountName) {
    const {classes} = this.props
    return <div className={classes.strategyIcon}>
      <Jdenticon size="56" value={accountName}/>
    </div>
  }

  render() {
    const {classes, allowedLeverages, leverage, leverageType, canEditLeverage, loading, t,
      viewer: {clientAllowCopyTrading, showPasswordProtectedStrategies},
      accountName, performanceFee, provideCopyTrading, remoteId, account, widthDown, locale} = this.props
    const {leverageUpdating, showPopup, leverageChange, changeStatus, errors, form: {name, fee, password}, showFeePopup,
      feeChange, changeFeeStatus, feeUpdating, showStopProvidingPopup, stopProvidingLoading ,stopProvidingStatus, nameLoading, nameStatus,
      providingErrorMessage, protectedErrorMessage, showPassword, protectPasswordToggle, updateOwnLeverage, uploadedFile,
      errorFiles, uploadStatus, uploadLoading, showNewPassword, updateAccountPassword, updatePasswordStatus, passwordLoading, submitMessageError
    } = this.state
    const {clientType, themePreference} = this.context
    const exceptions = ['changeLeverageLimitation5']
    const leverageMessages = filter(keys(messages), (m) => !includes(exceptions, m) && m.indexOf('changeLeverageLimitation') === 0).reduce((m, i) => {
      m[i] = messages[i]
      return m
    }, {})

    if (loading) return <Loading />
    const feeValue = performanceFee === -1 ? performanceFeeOptions.default : performanceFee
    const labelNew = getAccountLabel(account, locale, getAccountSubtype(get(account,'accountSubtype')), t)
    const label = (isNil(name) && accountName) || (!accountName && isNil(name) && `${remoteId} - ${labelNew}`)
    const copyTradingEnabled = clientAllowCopyTrading && checkAccountCopyTrading(account) && account?.leverageType === 'default_leverage'
    const passwordProtectedStrategies = get(account, 'passwordProtectedStrategies')
    const passwordProtectedStrategyAvailable = config.featuresConfig.passwordProtectedStrategy && isIBClient(clientType) && showPasswordProtectedStrategies
      && isForexAccount(account) && !isDemoAccount(account) && !isVipOrRaw(account)

    const previewAvatar = get(account, 'strategyAvatar')
    const previewAvatarStatus = includes([documentStatuses.pending.value, documentStatuses.accepted.value], get(account, 'strategyAvatarStatus'))
    const formatsAccepted = acceptedImageFormats.split(', ')
    const showUploadButton = get(uploadedFile, 'length')
    return (
      <Grid container spacing={3} className={classes.grid}>
        {updatePasswordStatus==='success' &&
        <Grid item xs={12} sm={12}>
          <NotificationBar status='success' title={<Trans {...messages.passwordChanged} />} />
        </Grid>
        }
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            {leverageType !== 'dynamic_leverage' && <Grid item lg={10} xs={12} >
              <FormControl variant="standard" fullWidth classes={{root: classes.formControlRoot}}>
                <InputLabel htmlFor="leverage">
                  <Trans {...messages.leverage} />
                </InputLabel>
                <Select
                  variant="standard"
                  value={updateOwnLeverage || leverage}
                  inputProps={{id: 'leverage'}}
                  onChange={(e) => this.setState({changeStatus: '', showPopup: true, leverageChange: e.target.value})}
                  disabled={(!canEditLeverage && !get(account, 'isDemo')) || (size(allowedLeverages) < 2)}>
                  {map(allowedLeverages, lvrg =>
                    <MenuItem key={lvrg} value={lvrg} disabled={lvrg === leverage}>{`1:${lvrg}`}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>}
            {leverageType === 'dynamic_leverage' &&<Grid item lg={10} xs={12}>
              <FormControl variant="standard" className={classes.div}>
                <InputLabel htmlFor="leverageType">
                  <Trans {...messages.leverageType} />
                </InputLabel>
                <Input
                  fullWidth
                  id="leverageType"
                  disabled
                  value={t(messages.dynamicLeverage.i18nKey, messages.dynamicLeverage.defaults)}
                />
              </FormControl>
            </Grid>}
            <Grid item lg={10} xs={12}>
              <FormControl variant="standard" className={classes.div}>
                <InputLabel htmlFor="name">
                  <Trans {...messages.name} />
                </InputLabel>
                <Input
                  fullWidth
                  id="name"
                  value={name || label || ''}
                  onChange={(e) => this.handleChange('name', e.target.value)}
                  inputProps={{maxLength: 20}}
                  endAdornment={
                    <InputAdornment position='end'>
                      <LoadingButton
                        disabled={nameLoading || !name || name === accountName}
                        status={nameStatus}
                        onClick={() => this.updateName()}
                        hideProgressBar={!nameLoading}
                        size="small"
                        noMargin={true}
                        variant={'text'}
                        className={classes.rootLoading}
                      > <Trans {...messages.update} />
                      </LoadingButton>
                    </InputAdornment>
                  }
                  error={!!errors.name}
                />
                {errors.name && <FormHelperText className={classes.errorMessage}> {name && messages[errors.name] ?
                  <Trans {...messages[errors.name]} values={{length: 20}}/> : <Trans {...messages.requiredField} />} </FormHelperText>}
              </FormControl>
            </Grid>
            <Grid item lg={10} xs={12}>
              <Password
                fullWidth
                id={'updateAccountPassword'}
                classes={classes}
                showPassword={showNewPassword}
                onChange={ (e) =>
                  this.handleChangeAccountPassword('updateAccountPassword', e)}
                onClickShow={() => this.handleClickShowUpdatePassword('showNewPassword')}
                error={this.state.errors.newPassword}
                label= {'changePasswordLowerCase'}
                value= {updateAccountPassword}
                showErrorText= {true}

              />
              <FormHelperText className={classNames(classes.helpText, classes.passwordmessage)}><i><Trans {...messages.passwordExplanationFieldNew}/></i></FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                id='loadingButton'
                noMargin
                onClick={this.handleSubmit}
                disabled={passwordLoading || isEmpty(errors) || some(errors)}
                hideProgressBar={!passwordLoading}>
                <Trans {...messages.saveButtonSettings} />
              </LoadingButton>
              {updatePasswordStatus==='failure' && <FormHelperText className={classes.errorMessage}>{submitMessageError}</FormHelperText>}
            </Grid>
          </Grid>
          {copyTradingEnabled && <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3"><Trans {...messages.copyTradingSettings} /></Typography>
            </Grid>
            <Grid item lg={10} xs={12}>
              <FormControl
                variant="standard"
                fullWidth
                classes={{root: classes.formControlRoot}}
                disabled={provideCopyTrading}>
                <InputLabel htmlFor="performanceFee">
                  <Trans {...messages.performanceFee} />
                </InputLabel>
                <Select
                  variant="standard"
                  value={feeValue}
                  inputProps={{id: 'fee'}}
                  onChange={(e) => this.setState({changeFeeStatus: '', showFeePopup: true, feeChange: e.target.value})}>
                  {map(performanceFeeOptions.options, fee =>
                    <MenuItem key={fee} value={fee} disabled={fee === feeValue}>{`${fee}%`}</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item lg={10} xs={12}>
              <SwitchButton iOSSwitch={true} id={'provideCopyTrading'} value={provideCopyTrading} checked={provideCopyTrading}
                onChange={(e) => this.toggleProvideCopyTrading(e.target.checked)}
                label={t(messages.provideCopyTrading.i18nKey, messages.provideCopyTrading.defaults)}/>
              {providingErrorMessage && <FormHelperText className={classes.errorMessage}>{providingErrorMessage}</FormHelperText>}
            </Grid>
          </Grid>}
          {passwordProtectedStrategyAvailable && <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3"><Trans {...messages.strategySecuritySettings} /></Typography>
            </Grid>
            <Grid item lg={10} xs={12}>
              <SwitchButton iOSSwitch={true} id={'passwordProtected'} value={!!passwordProtectedStrategies} checked={!!passwordProtectedStrategies}
                onChange={(e) => this.toggleProtectPassword(e.target.checked)}
                label={t(messages.passwordProtected.i18nKey, messages.passwordProtected.defaults)}
                disabled={!password && !passwordProtectedStrategies}/>
              {protectedErrorMessage && <FormHelperText className={classes.errorMessage}>{protectedErrorMessage}</FormHelperText>}
            </Grid>
            <Grid item lg={10} xs={12} >
              <Password
                classes={classes}
                showPassword={showPassword}
                onChange={(e) => this.handleChange('password', e.target.value)}
                onClickShow={() => this.handleClickShowPassword()}
                value={password || passwordProtectedStrategies || ''}
                disabled={passwordProtectedStrategies}
              />
              {!password && !passwordProtectedStrategies && !protectPasswordToggle && <FormHelperText>
                <Trans {...messages.enterPasswordProtectStrategy} />
              </FormHelperText>}
            </Grid>
          </Grid>}
          {provideCopyTrading && <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h3"><Trans {...messages.strategyAvatarSettings} /></Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className={classes.greyText}>
                <Trans {...messages.acceptedFormats} values={{acceptedFormats: acceptedImageFormats.replace(/(.*),.*/, '$1'), lastAccepted: formatsAccepted[formatsAccepted.length-1]}}/>&nbsp;
              </Typography>
              <Typography variant="body1" className={classes.greyText}>
                <Trans {...messages.maxFileSizeText} values={{maxSize: maxFileSize}}/>
              </Typography>
            </Grid>
            <Grid item xs={12} md={7}>
              <SelectDocument
                multiple
                uploadedFile={uploadedFile}
                onChange={(e) => this.handleFileUpload('uploadedFile', e)}
                fileKey="1"
                category={'other'}
                status={uploadedFile && (includes(keys(errorFiles), 'uploadedFile') ? 'error': 'success')}
                errors={!isEmpty(errorFiles) && errorFiles.uploadedFile}
                selectedDocTypeKey={'other'}
                jdenticon={!previewAvatarStatus && label}
                avatar={true}
                src={(previewAvatarStatus && previewAvatar) || Images[`upload-document-${themePreference}.png`]}
                srcSuccess={(previewAvatarStatus && previewAvatar) || Images['upload-generic-success.png']}
                srcError={!isEmpty(errorFiles) && Images['upload-generic-error.png']}
                borderRadiusOptional={true}
                pendingConfirmation={get(account, 'strategyAvatarStatus') === documentStatuses.pending.value}
                strategyAvatarRejected={get(account, 'strategyAvatarStatus') === documentStatuses.rejected.value}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12} md={7} className={classes.uploadTypeText}>
                  <LoadingButton
                    id='uploadDocLoadingButton'
                    onClick={() => this.uploadStrategyAvatar()}
                    disabled={!showUploadButton || !isEmpty(errorFiles) || (uploadLoading && !uploadStatus) || (uploadStatus==='failure')}
                    status={uploadStatus || ''}
                    fullWidth={widthDown}
                    hideProgressBar={!uploadedFile || !isEmpty(errorFiles) || !showUploadButton}
                  >
                    <Trans {...messages.submit} />
                  </LoadingButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>}
        </Grid>
        {leverageType !== 'dynamic_leverage' && <Grid item xs={12} md={6}>
          <Grid container>
            {config.featuresConfig.leverageNotice === 'euNotice' && <Grid item xs={12} lg={10} className={classNames(classes.noticeDiv, classes.notice)}>
              <Typography variant="h4" className={classes.noticeTitle}><Trans {...messages.leverageNotice} /></Typography>
              <Typography variant="body1"><Trans {...messages.changeLeverageNoticeEu} /></Typography>
              <Typography variant="body1"><Trans {...messages.leverageLimitations} /></Typography>
              <Grid container direction="column" spacing={1}>
                <Grid item xs={12} sm={11}>
                  <ul key="information" className={classes.list}>
                    {map((leverageMessages), (m) =>
                      <li key={m.i18nKey} className={classes.listItem}>
                        <Typography variant='body1'><Trans {...m} /></Typography>
                      </li>
                    )}
                  </ul>
                </Grid>
              </Grid>
              <Typography variant="body1">
                <Trans {...messages.contactSupportEu}
                  components={[
                    config.weblinks.professionalAccount && <a href={config.weblinks.professionalAccount} className={classes.link} target='_blank' rel='noopener noreferrer'> </a>,
                    <Link to={'/support'} className={classes.link}> </Link>
                  ]}
                />
              </Typography>
            </Grid>}
            {config.featuresConfig.leverageNotice === 'globalNotice' && <Grid item xs={12} lg={10} className={classNames(classes.noticeDiv, classes.notice)}>
              <Typography variant="h4" className={classes.noticeTitle}><Trans {...messages.leverageNotice} /></Typography>
              <Typography variant="body1"> <Icon className={classes.warningIcon}> warning </Icon> <Trans {...messages.changeLeverageNotice} /> </Typography>
              <Typography variant="body1"> <Trans {...messages.changeLeverageNoticeExample} /> </Typography>
              <Link to="/support">
                <Typography variant="body1" className={classes.link}> <Trans {...messages.contactSupportForMore} /> </Typography>
              </Link>
            </Grid>}
            {passwordProtectedStrategyAvailable && <Grid item xs={12} lg={10} className={classNames(classes.noticeDiv, classes.info)}>
              <Typography variant="h4" className={classes.noticeTitle}><Trans {...messages.passwordProtection} /></Typography>
              <Typography variant="body1"> <InfoIcon className={classNames(classes.link, classes.infoIcon)} /> <Trans {...messages.passwordProtectionText} /> </Typography>
            </Grid>}
          </Grid>
        </Grid>}
        <AlertDialog
          open={showPopup || showFeePopup}
          onClose={() => this.setState({showPopup: false, showFeePopup: false})}
          onAgree={() => (showPopup) ? this.onLeverageChange() : this.onFeeChange()}
          onDisagree={() => this.setState({showPopup: false, showFeePopup: false})}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          agreeText={t(messages.confirm.i18nKey, messages.confirm.defaults)}
          title = { <Typography variant='body2' className={classes.dialogTitle}>
            <Trans {...messages.submitWithdrawalTitle} />
          </Typography>}
          buttonLoading={leverageUpdating || feeUpdating}
          buttonStatus={changeStatus || changeFeeStatus}
          errorMessage={(changeStatus === 'failure' || changeFeeStatus === 'failure') ? t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults) : ''}
        >
          {(showPopup) ? <React.Fragment>
            <Typography variant="body1">
              <Trans {...messages.changeLeveragePopupText1} values={{leverage}} components={[<span className={classes.highlightText}>oldleverage</span>]} />
            </Typography>
            <Typography variant="body1">
              <Trans {...messages.changeLeveragePopupText2} values={{leverageChange}} components={[<span className={classes.highlightText}>newleverage</span>]}/>
            </Typography>
            <NotificationBar
              status="warning"
              title={<Trans {...messages.openTradesAffected} />}
              classes={{notificationBox: classes.notificationBox}}
            />
          </React.Fragment>
            : <React.Fragment>
              <Typography variant="body1">
                <Trans {...messages.changeFeePopupText1} values={{fee: fee || feeValue}} components={[<span className={classes.highlightText}>oldFee</span>]} />
              </Typography>
              <Typography variant="body1">
                <Trans {...messages.changeFeePopupText2} values={{feeChange}} components={[<span className={classes.highlightText}>newFee</span>]}/>
              </Typography></React.Fragment>
          }
        </AlertDialog>
        <AlertDialog
          open={showStopProvidingPopup}
          onClose={() => this.setState({showStopProvidingPopup: false})}
          onAgree={() => this.toggleProvideCopyTrading(false)}
          onDisagree={() => this.setState({showStopProvidingPopup: false})}
          disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
          agreeText={t(messages.stopProviding.i18nKey, messages.stopProviding.defaults)}
          title = { <Typography variant='body1' className={classes.alertTitle}>
            <Trans {...messages.stopProvidingTitle} components={[<span className={classNames(classes.stopProviding, classes.errorMessage)}>stopProviding</span>]}/>
          </Typography>}
          buttonLoading={stopProvidingLoading}
          buttonStatus={stopProvidingStatus}
          errorMessage={stopProvidingStatus === 'failure' ? t(messages.somethingWrongError.i18nKey, messages.somethingWrongError.defaults) : ''}
        >
          <Typography variant="body1">
            <Trans {...messages.stopProvidingText1} />
          </Typography><br />
          <Typography variant="body1">
            <Trans {...messages.stopProvidingText2}/>
          </Typography>
        </AlertDialog>
      </Grid>
    )
  }
}

export default compose(
  withMediaQuery('md'),
  withStyles(styles, {withTheme: true}),
  withNamespaces(),
  graphql(FOREX_ACCOUNT_SETTINGS, {
    options: (props) => ({variables: {id: props.match.params.accountId}}),
    props: ({data: {error, loading}, data, ownProps}) => {
      const account = get(data, 'viewer.accounts.0')
      const leverage = get(data, 'viewer.accounts.0.leverage', 0)
      const allowedLeverages = get(data, 'viewer.accounts.0.allowedLeverages')
      const leverageType = get(data, 'viewer.accounts.0.leverageType')
      const canEditLeverage = get(data, 'viewer.canEditLeverage')
      const company = get(data, 'viewer.company')
      const typename = get(data, 'viewer.accounts.0.__typename')
      const accountName = get(data, 'viewer.accounts.0.accountName')
      const performanceFee = get(data, 'viewer.accounts.0.performanceFee')
      const provideCopyTrading = get(data, 'viewer.accounts.0.provideCopyTrading')
      const remoteId = get(data, 'viewer.accounts.0.remoteId')
      return {
        error,
        loading,
        allowedLeverages,
        leverageType,
        leverage,
        accountId: ownProps.match.params.accountId,
        canEditLeverage,
        company,
        typename,
        accountName,
        performanceFee,
        provideCopyTrading,
        remoteId,
        account
      }
    }
  }),
  graphql(UPDATE_LEVERAGE, {
    name: 'updateLeverage',
    options: (props) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {error: viewerError, loading: viewerLoading}, data}) => ({
      viewerError,
      viewerLoading,
      viewer: get(data, 'viewer'),
    })
  }),
  graphql(UPDATE_ACCOUNT_NAME_MUTATION, {
    name: 'updateAccountName',
    options: (props) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(UPDATE_OWN_PERFORMANCE_FEE_MUTATION, {
    name: 'updateOwnPerformanceFee',
    options: (props) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(PROVIDE_COPY_TRADING_MUTATION, {
    name: 'toggleProvideCopyTrading',
    options: (props) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(TOGGLE_PASSWORD_STRATEGY_MUTATION, {
    name: 'togglePasswordStrategy',
    options: (props) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(SIGN_UPLOAD_URL_MUTATION, {
    name: 'signUploadUrl',
  }),
  graphql(UPDATE_STRATEGY_AVATAR_MUTATION, {
    name: 'updateStrategyAvatar',
    options: (props) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
  }),
  graphql(CHANGE_ACCOUNT_PASSWORD_MUTATION, {
    name: 'changeAccountPasswordMutation',
    options: (props) => ({
      refetchQueries: [{query: FOREX_ACCOUNT_SETTINGS, variables: {id: props.match.params.accountId}}],
    }),
    update: cache => {
      cache.writeData({data: {props: []}})
    },
  }),
)(ForexAccountSettings)
