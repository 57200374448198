import React, {Component} from 'react'
import {graphql} from 'react-apollo'
import withStyles from '@mui/styles/withStyles'
import {withNamespaces, Trans} from 'react-i18next'
import messages from '../../../../assets/messages'
import {ACCOUNTS_QUERY} from '../../../../graphql/queries'
import {get, isEmpty, flowRight as compose, split, map, ceil, capitalize, range, reverse, omit, isArray} from 'lodash'
import Grid from '@mui/material/Grid'
import ProviderStatsCard from './ProviderStatsCard'
import Loading from '../../../Common/Loading'
import FollowersTableDesktop from './FollowersTableDesktop'
import {getStrategiesFollowers, getStrategiesSummary} from '../../../../common/utils/requests'
import {isMobile} from '../../../../common/utils'
import FollowersTableMobile from './FollowersTableMobile'
import {Typography} from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import moment from 'moment'
import MenuItem from '@mui/material/MenuItem'
import DownloadExcel from '../../../Common/DownloadExcel'
import '../../../../common/utils/localsMoment'
import {momentLocales} from '../../../../common/utils/uioptions'
import NotificationBar from '../../../Common/NotificationBar'
import Images from '../../../Common/Images'
import AppContext from '../../../Common/contexts/AppContext'
import {getLocaleMoment} from '../../../../common/utils/general'
import config from '../../../../config'
import {isIBClient} from '../../../../common/utils/accounts'

const styles = theme => ({
  table: {
    padding: 10
  },
  allPerformance: {
    marginTop: -40
  },
  stats: {
    marginTop: 15
  },
  button: {
    float: 'right',
    padding: '5px 0',
    display: 'inline-flex',
    alignItems: 'flex-start',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    '&:active': {
      backgroundColor: theme.palette.background.default,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
    },
  },
  pagination: {
    borderBottom: 0,
    textAlign: 'right',
    width: '100%'
  },
  paginationActions: {
    margin: 0,
  },
  select: {
    color: theme.palette.lightgrey.color,
    fontWeight: 400
  },
  emptyListsIcon: {
    ...theme.emptyListsIcon
  },
  textCenter: {
    textAlign: 'center'
  },
  highlight: {
    fontWeight: 400
  }
})

export class ProviderStats extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    const today = moment().locale(momentLocales.default)
    const currentMonth = today.format('M')
    const currentYear = today.format('Y')
    this.state = {
      month: currentMonth,
      year: currentYear,
      page: 0,
      rowsPerPage: 10,
      currentDate: `${currentMonth}-${currentYear}`,
      activeMonth: true,
      strategiesError: false,
    }
  }

  componentDidMount() {
    const {account, history} = this.props
    const {month, year, page} = this.state
    this.getStrategiesSummary(get(account, 'id'), month, year)
    this.getStrategiesFollowers(get(account, 'id'), month, year, page)
    const showProviderStats = get(account, 'showProviderStats')
    if (!showProviderStats) {
      history.push(`/accounts/${get(account, 'id')}`)
    }
    const providerStartDateValidReport = moment(get(account, 'provideCopyTradingStartDate')) > moment(config.common.providerStatsReportStartDate)
    const providerStartDate = providerStartDateValidReport ? get(account, 'provideCopyTradingStartDate') : config.common.providerStatsReportStartDate
    this.setState({providerStartDate, providerStartDateValidReport})
  }

  getStrategiesSummary(accountId, month, year) {
    this.setState({loadingSummary: true})
    getStrategiesSummary(accountId, month, year).then((res) => {
      this.setState({loadingSummary: false})
      if (!res || get(res, 'error')) {
        this.setState({strategiesError: true})
      } else {
        this.setState({summaries: res})
      }
    }).catch((e) => this.setState({strategiesError: true}))
  }

  getStrategiesFollowers(accountId, month, year, page) {
    this.setState({loadingFollowers: true})
    getStrategiesFollowers(accountId, month, year, page).then((res) => {
      this.setState({loadingFollowers: false})
      if (!res || get(res, 'error')) {
        this.setState({strategiesError: true})
      } else {
        this.setState({followers: res, strategiesError: false})
      }
    }).catch((e) => this.setState({strategiesError: true}))
  }

  changeMonthYear(value) {
    const {account} = this.props
    const {page, currentDate} = this.state
    const values = split(value, '-')
    this.setState({month: values[0], year: values[1], activeMonth: `${values[0]}-${values[1]}` === currentDate})
    this.getStrategiesSummary(account.id, values[0], values[1])
    this.getStrategiesFollowers(account.id, values[0], values[1], page)
  }

  changePage(page) {
    const {account} = this.props
    const {month, year} = this.state
    this.setState({page})
    this.getStrategiesFollowers(account.id, month, year, page)
  }

  renderPagination() {
    const {classes} = this.props
    const {summaries, page, rowsPerPage} = this.state
    return (
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        count={summaries.copiersPerMonth}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, page) => this.changePage(page)}
        classes={{root: classes.pagination, actions: classes.paginationActions}}
      />
    )
  }

  getOptions() {
    const {locale} = this.context
    const {providerStartDate} = this.state
    const months = ceil((moment().endOf('month')).diff(moment(providerStartDate).startOf('month'), 'months', true))
    const options = map(reverse(range(months)), (m) => {
      const month = moment(providerStartDate).locale(getLocaleMoment(locale)).add(m, 'months')
      let monthValue = `${month.format('M')}-${month.format('YYYY')}`
      if (isNaN(month.format('M'))) {
        const validMonth = moment(providerStartDate).locale(get(momentLocales, 'default')).add(m, 'months')
        monthValue = `${validMonth.format('M')}-${validMonth.format('YYYY')}`
      }
      return {
        value: monthValue,
        label: month.format('MMMM YYYY')
      }
    })
    return options
  }

  renderAllTime(showPagination) {
    const {classes, account} = this.props
    const {summaries} = this.state
    return <React.Fragment>
      <Grid item xs={12} className={(showPagination) ? classes.allPerformance : classes.stats}>
        <Typography variant="h4">
          <Trans {...messages.allTimePerformance} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ProviderStatsCard summaries={summaries} account={account} allTime={true} />
      </Grid>
    </React.Fragment>
  }

  renderStatsHeader(emptyFollowers) {
    const {classes, t} = this.props
    const {followers, month, year} = this.state
    return <Grid item xs={12} className={classes.stats}>
      <Grid container>
        <Grid item md={8} xs={6}>
          <Typography variant="h4">
            <Trans {...messages.providerStats} />
          </Typography>
        </Grid>
        {!emptyFollowers && <Grid item md={4} xs={6}>
          <DownloadExcel data={map(followers, (f) => omit(f, ['projectionPerformanceFee', 'currentPerformanceFee', 'watermark']))} text={t(messages.downloadFollowerPerformance.i18nKey, messages.downloadFollowerPerformance.defaults)}
            name={`${t(messages.providerStats.i18nKey, messages.providerStats.defaults)} ${month}-${year}`}
            classes={classes.button} />
        </Grid>}
      </Grid>
    </Grid>
  }

  render() {
    const {classes, account, loading} = this.props
    const {clientType} = this.context
    if (loading) return <Loading />
    const {summaries, followers, month, year, loadingSummary, loadingFollowers, page, rowsPerPage, activeMonth,
      strategiesError, providerStartDateValidReport} = this.state
    const {themePreference} = this.context
    const options = this.getOptions()
    const emptySummary = isEmpty(omit(summaries, ['projectionPerformanceFee', 'activeCopiers', 'currentPerformanceFee']))
    const emptyFollowers = isEmpty(followers) || !isArray(followers)
    const showPagination = !emptyFollowers && !emptySummary && get(summaries, 'copiersPerMonth') > rowsPerPage
    const isIb = isIBClient(clientType)
    const accountPerformanceFee = get(account, 'performanceFee') > 0
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item md={3} xs={12}>
              <FormControl variant="standard" fullWidth>
                <Select
                  variant="standard"
                  value={`${month}-${year}`}
                  onChange={(e) => this.changeMonthYear(e.target.value)}
                  align='left'
                  classes={{select: classes.select}}>
                  {map(options,((option) => <MenuItem key={option.value} value={option.value}>
                    {capitalize(option.label)}
                  </MenuItem>))}
                </Select>
              </FormControl>
            </Grid>
            {!providerStartDateValidReport && <Grid item xs={12}><Typography variant="caption">
              *<Trans {...messages.providerReportDateNotification}
                values={{copyTradingAutomationStartDate: config.common.providerStatsReportStartDate}} />
            </Typography></Grid>}
          </Grid>
        </Grid>
        {(loadingFollowers || loadingSummary) ? <Grid item xs={12}>
          <Loading />
        </Grid> : <React.Fragment>
          {!strategiesError ? (emptyFollowers && !emptySummary ? <React.Fragment>
            {this.renderAllTime(showPagination)}
            {this.renderStatsHeader(emptyFollowers)}
          </React.Fragment> : this.renderStatsHeader(emptyFollowers)) : this.renderStatsHeader(true)}
          <Grid item xs={12}>
            {(emptyFollowers || strategiesError) ? <React.Fragment>
              <NotificationBar status='info' classes={{notificationBox: classes.notificationBox}} title={<Trans {...messages.noFollowersDataAvailable} />} />
              <div className={classes.textCenter}>
                <img className={classes.emptyListsIcon} src={Images[`documents-empty-${themePreference}.png`]} alt='noTrades' />
              </div></React.Fragment>
              : <ProviderStatsCard summaries={summaries} account={account} activeMonth={activeMonth} />}
          </Grid>
          <Grid item xs={12}>{
            !strategiesError && !emptyFollowers ? <div className={!isMobile() ? classes.table : ''}>
              {!isMobile() ? <FollowersTableDesktop followers={followers} page={page} changePage={(page) => this.changePage(page)}
                showPagination={showPagination} rowsPerPage={rowsPerPage} activeMonth={activeMonth} month={month}
                renderPagination={() => this.renderPagination()} isIb={isIb} accountPerformanceFee={accountPerformanceFee}/>
                : <FollowersTableMobile followers={followers} page={page} changePage={(page) => this.changePage(page)}
                  showPagination={showPagination} rowsPerPage={rowsPerPage} activeMonth={activeMonth} month={month}
                  renderPagination={() => this.renderPagination()} isIb={isIb} accountPerformanceFee={accountPerformanceFee}/>}
            </div>: ''}
          </Grid>
          {!strategiesError && !emptySummary && !emptyFollowers && this.renderAllTime(showPagination)}
        </React.Fragment>}
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}) => {
      const {accountId} = match.params
      const account = get(data, 'viewer.accounts', []).find(account => account.id === Number(accountId))
      return {
        error,
        loading,
        account,
      }
    }
  }),
)(ProviderStats)
