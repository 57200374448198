import React, {Component} from 'react'
import {filter, find, first, flowRight as compose, get, map} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {Box, Button, Grid,} from '@mui/material'
import messages from '../../assets/messages'
import AppContext from '../Common/contexts/AppContext'
import Images from '../Common/Images'
import GeneralAccountSelect from '../Common/GeneralAccountSelect'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router-dom'
import {promotionCardDetails} from '../../common/utils/uioptions'
import {currencies} from '@bdswiss/common-enums'
import {getCurrentTheme, isDarkTheme, logEventCustomParams} from '../../common/utils'

const styles = theme => ({
  root: {
    borderRadius: '8px',
    height: '324px',
    position: 'relative',
    overflow: 'hidden',
    background: 'linear-gradient(115.08deg, #474747 17.97%, #5C5C5C 94.32%)',
    padding: '24px 0px 24px 0px',
    maxWidth: '540px',
    [theme.breakpoints.down('sm')]: {
      height: '243px',
      maxWidth: '343px',
      padding: '16px 0px 16px 0px',
    },
  },
  imageItem: {
    position: 'absolute',
    width: '252px',
    height: '308.79px',
    [theme.direction === 'rtl' ? 'left' : 'right']: '-46px',
    transform: theme.direction === 'rtl' ? 'scaleX(-1)' : 'scaleX(1)',
    top: '-50px',
    '& img':{
      width: '100%',
    },
    [theme.breakpoints.down('lg', 'sm')]: {
      [theme.direction === 'rtl' ? 'left' : 'right']: '-105px',
      top: '15px',
    }
  },
  accountStatsItem: {
    [theme.breakpoints.down('sm')]: {
      borderBottom: '2px solid rgba(255, 255, 255, 0.1)',
    },
  },
  accountStatsContainer: {
    gap: '8px',
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: '24px',
    [theme.breakpoints.down('sm')]: {
      [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: '16px',
      gap: '18px',
    },
    [theme.breakpoints.down('lg')]: {
      [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: '16px',
    },
  },
  accountStatsSubItem: {
    background: theme.palette.grey[600],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '4px 8px',
    gap: '10px',
    borderRadius: '4px',
    maxInlineSize: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      background: 'transparent',
      paddingLeft: '0px',
    }
  },
  accountStatsTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '8px',
    lineHeight: '12px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    color: theme.palette.grey[400],
    whiteSpace: 'nowrap',
  },
  accountStatsValue: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '16px',
    display: 'flex',
    alignItems: 'center',
    letterSpacing: '0.4px',
    color: theme.palette.secondary.light,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
      lineHeight: '14px'
    },
  },
  promotionInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    height: '75px',
    gap: '8px',
    margin: theme.direction === 'rtl' ? '70px 24px 0px 145px' : '70px 145px 0px 24px',
    [theme.breakpoints.down('lg')]: {
      margin: theme.direction === 'rtl' ? '0px 16px 0px 90px' : '0px 90px 0px 16px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: theme.direction === 'rtl' ? '35px 16px 0px 90px' : '35px 90px 0px 16px',
    },
  },
  promotionTitle: {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.18px',
    color: theme.palette.secondary.light,
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    [theme.breakpoints.down('lg')]: {
      whiteSpace: 'unset',
    },
  },
  promotionDesc:{
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.grey[400],
    [theme.breakpoints.down('sm')]: {
      fontWeight: '300',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: theme.palette.grey[400],
    },
  },
  accountSelectionItem: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '12px',
    [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: '24px',
    [theme.breakpoints.down('sm')]: {
      [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: '16px',
    },
    [theme.breakpoints.down('lg')]: {
      [theme.direction === 'rtl' ? 'marginRight' : 'marginLeft']: '16px',
    },
  },
  depositButton: {
    padding: '8px 24px',
    height: '40px',
    background: theme.palette.secondary.light,
    color: theme.palette.grey[700],
    borderRadius: '8px',
    '&:hover':{
      background: theme.palette.secondary.light,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '4px 16px',
      gap: '8px',
      height: '32px',
      borderRadius: '6px',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
  },
})

class PromotionCard extends Component {
  static contextType = AppContext
  constructor(props) {
    super(props)
    this.state = {
      account: '',
    }
  }

  componentDidMount() {
    this.setState({account: String(get(first(this.getFilteredAccounts()), 'id', ''))})
  }

  handleAccountChange(accountId) {
    this.setState({account: String(accountId)})
  }

  deposit() {
    const {history} = this.props
    const {account} = this.state
    const {companyObject} = this.context
    const brand = get(companyObject, 'brandLabel')
    logEventCustomParams('depositIntentFromBonusCard', {
      source: 'Dashboard',
      brand: brand,
      userId: get(this.context, 'clientId'),
    })
    return history.push(`/transactions/${account}/deposit`)
  }

  getFilteredAccounts() {
    const {promotion} = this.props
    const {accounts} = this.context
    const bonusTypes = get(promotion, 'bonusTypes')
    return filter(accounts, (a) => find(bonusTypes, (b) => String(get(a, 'remoteId')) === String(get(b, 'login'))))
  }

  getCurrentAccountStats(filteredAccounts) {
    const {promotion} = this.props
    const {account} = this.state
    const bonusTypes = get(promotion, 'bonusTypes')
    const selectedAccount = find(filteredAccounts, (a) => String(a.id) === String(account))
    return find(bonusTypes, (b) => String(get(selectedAccount, 'login')) === String(get(b, 'login')))
  }

  accountStatCell(title, value, classes) {
    return (
      <Grid item xs sm key={`${title}_accountStatsSubItem`} className={get(classes, 'accountStatsSubItem')}>
        <Grid container direction='column' key={`${title}_accountStatsSubContainer`}>
          <Grid item xs sm key={`${title}_accountStatsTitle`} className={classes.accountStatsTitle}>{title}</Grid>
          <Grid item xs sm key={`${title}_accountStatsValue`}className={classes.accountStatsValue}>{value}</Grid>
        </Grid>
      </Grid>
    )
  }

  render() {
    const {classes, t, promotion} = this.props
    const {account} = this.state
    const {locale} = this.context
    const promotionsRocketImage = Images['promotionsRocket.png']
    const filteredAccounts = this.getFilteredAccounts()
    const stats = this.getCurrentAccountStats(filteredAccounts)
    const themePreference = getCurrentTheme(this.context)
    const isDark = isDarkTheme(themePreference)
    const promotionTitle = get(promotion, 'title', '')
    const promotionDescription = get(promotion, 'description', '')
    if (!promotion) return null

    return (<Grid container className={classes.root}>
      <Grid item xs={12} sm={12} className={classes.accountStatsItem}>
        <Grid container className={classes.accountStatsContainer}>
          {map(promotionCardDetails, (d) => {
            const title = messages[d.title()] ? t(messages[d.title()].i18nKey, messages[d.title()].defaults) : d.title()
            const value = d.value(
              get(promotion, 'startingDate'),
              get(promotion, 'endingDate'),
              get(promotion, 'minimumDeposit'),
              currencies.USD.value,
              get(stats, 'receivedBonus'),
              get(stats, 'maxBonus'),
              get(stats, 'amountValue'),
              get(stats, 'amountType'),
              t)
            return this.accountStatCell(title, value, classes)})}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} className={classes.promotionInfoItem}>
        <Grid item xs={12} sm={12} className={classes.promotionTitle}>
          {messages[promotionTitle] ? t(messages[promotionTitle].i18nKey, messages[promotionTitle].defaults) : promotionTitle}
        </Grid>
        <Grid item xs={12} sm={12} className={classes.promotionDesc}>
          {messages[promotionDescription] ? t(messages[promotionDescription].i18nKey, messages[promotionDescription].defaults) : promotionDescription}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12}  className={classes.accountSelectionItem}>
        <Box>
          <Button className={classes.depositButton} onClick={() => this.deposit()} variant="text" size="large" color="inherit">
            <Trans {...messages.deposit} />
          </Button>
        </Box>
        <Box>
          <GeneralAccountSelect
            label={t(messages.account.i18nKey, messages.account.defaults)}
            accounts={filteredAccounts}
            value={account}
            locale={locale}
            id={`${account}_${get(promotion, 'mpid')}_account`}
            name={`${account}_${get(promotion, 'mpid')}_account`}
            onChange={(e) => this.handleAccountChange(e.target.value)}
            t={t}
            fullWidth={false}
            disableUnderline
            dark={isDark}
            mobileMaxWidth={343}/>
        </Box>
      </Grid>
      <Grid item className={classes.imageItem}>
        <img src={promotionsRocketImage} alt='promotionsRocket'/>
      </Grid>
    </Grid>)
  }
}

PromotionCard.propTypes = {
  promotion: PropTypes.object.isRequired,
}

export default compose(
  withNamespaces(),
  withRouter,
  withStyles(styles, {withTheme: true}),
)(PromotionCard)
