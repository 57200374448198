import React from 'react'
import {Trans} from 'react-i18next'
import {withRouter} from 'react-router-dom'
import EastIcon from '@mui/icons-material/East'
import AppContext from '../../Common/contexts/AppContext'
import {Box, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography} from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import {flowRight as compose, includes} from 'lodash'
import messages from '../../../assets/messages'
import {recentTransactionsColumns, transactionCategories} from '../../../common/utils/uioptions'
import {isTransferTransactionType} from '../../../common/utils/transactions'
import Images from '../../Common/Images'
import moment from 'moment'
import Amount from '../../Common/Amount'
import classNames from 'classnames'
import {transactionTypes} from '@bdswiss/common-enums'
import LoadingWrapper from '../LoadingWrapper'
import {logEventCustomParams} from '../../../common/utils'
import {getTransactionStatusCategory} from './utils'

const styles = (theme) => ({
  subtitle1Text: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: theme.palette.secondary.dark,
  },
  viewContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  titleText: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '30px',
    letterSpacing: '0.15px',
  },
  titleLink: {
    fontWeight: 400,
    fontSize: '16px',
    cursor: 'pointer',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: theme.palette.primary.main,
  },
  tableContainer: {
    borderRadius: '8px',
    padding: '20px 24px',
    border: theme.palette.mode === 'dark' ? '1px solid #1F1F1F' : '1px solid #EBEBEB',
    [`& .${tableCellClasses.root}`]: {
      padding: '8px 0px',
      borderBottom: 'none',
    },
  },
  tableHeadCell: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.secondary.main,
  },
  tableBodyRow: {
    '&:hover': {
      backgroundColor: theme.palette.extralightgrey.color,
    }
  },
  typeCellContainer: {
    gap:'12px',
    display:'flex',
    flexDirection:'row',
  },
  remoteIdTransferContainer: {
    gap: '2px',
    display: 'flex',
    padding: '2px 4px',
    borderRadius: '4px',
    alignItems: 'center',
    border: theme.palette.mode === 'dark' ? '1px solid #1F1F1F' : '1px solid #EBEBEB',
  },
  remoteIdTransferToArrow: {
    width: '16px',
    height: '16px',
    color: theme.palette.grey.color,
  },
  remoteIdContainer: {
    display: 'flex',
    padding: '2px 4px',
    borderRadius: '4px',
    alignItems: 'center',
    border: theme.palette.mode === 'dark' ? '1px solid #1F1F1F' : '1px solid #EBEBEB',
  },
  remoteIdText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
  },
  statusCellText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    '&.completed': {
      color: theme.palette.green.color,
    },
    '&.pending': {
      color: theme.palette.yellow.color,
    },
    '&.rejected': {
      color: theme.palette.red.color,
    },
  },
  lightGreyLoading: {
    backgroundColor: theme.palette.extralightgrey.color,
  }
})

const hideSensitiveData = '***'

class DesktopView extends React.Component {
  static contextType = AppContext

  renderTransferRemoteId(remoteId, transferAccount, meta) {
    const {classes, showSensitiveData} = this.props

    return <Box className={classes.remoteIdTransferContainer}>
      {showSensitiveData
        ? <React.Fragment>
          <Typography variant='caption' className={classes.remoteIdText}> #{remoteId} </Typography>
          <EastIcon className={classes.remoteIdTransferToArrow} />
          <Typography variant='caption' className={classes.remoteIdText}> #{transferAccount.remoteId} </Typography>
        </React.Fragment>
        : <span className={classes.remoteIdText}> {hideSensitiveData} </span>}
    </Box>
  }

  renderRemoteId(remoteId) {
    const {classes, showSensitiveData} = this.props

    return <Box className={classes.remoteIdContainer}>
      {showSensitiveData
        ? <Typography variant='caption' className={classes.remoteIdText}> #{remoteId} </Typography>
        : <span className={classes.remoteIdText}> {hideSensitiveData} </span>
      }
    </Box>
  }

  renderTypeCell(type, remoteId, transferAccount, meta) {
    const {classes} = this.props

    return (
      <Box className={classes.typeCellContainer}>
        <img width='24px' height='24px' src={Images[`${transactionCategories[type].icon}`]} alt='icon'/>
        <Typography variant='subtitle1' className={classes.subtitle1Text}> <Trans {...transactionCategories[type].message} /> </Typography>
        {isTransferTransactionType(type)
          ? this.renderTransferRemoteId(remoteId, transferAccount, meta)
          : this.renderRemoteId(remoteId)}
      </Box>
    )
  }

  renderDateCell(createdAt) {
    const {classes, showSensitiveData} = this.props

    return <Typography variant='subtitle1' className={classes.subtitle1Text}>
      {showSensitiveData ? moment(createdAt).format('DD MMM YYYY, HH:MM') : hideSensitiveData}
    </Typography>
  }

  renderAmountCell(amount, currency, type, transferAccount) {
    const {clientId} = this.context
    const {classes, showSensitiveData} = this.props

    const transferTypes = [transactionTypes.transfer.value, transactionTypes.ldTransfer.value]
    const isNegativeAmount = type === transactionTypes.withdrawal.value || (includes(transferTypes, type) && clientId === transferAccount.memberId)

    return showSensitiveData
      ? <Amount
        classes={{
          value: classes.subtitle1Text,
          currency: classes.subtitle1Text,
          fraction: classes.subtitle1Text,
          minusSign: classes.subtitle1Text,
        }}
        value={isNegativeAmount ? `-${amount}` : amount}
        currency={currency}
        locale='en-US'
      />
      : <span className={classes.subtitle1Text}> {hideSensitiveData} </span>
  }

  renderStatusCell(status) {
    const {classes} = this.props
    const statusCategory = getTransactionStatusCategory(status)

    return <Typography variant='body1' className={classNames(classes.statusCellText, statusCategory)}>
      <Trans {...messages[statusCategory]} />
    </Typography>
  }

  render() {
    const {clientId} = this.context
    const {classes, history, loading, transactions} = this.props

    return <Box className={classes.viewContainer}>
      <Box className={classes.titleContainer}>
        <LoadingWrapper loading={loading}>
          <Typography className={classes.titleText}> <Trans {...messages.recentTransactions} /> </Typography>
        </LoadingWrapper>
        <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}>
          <Typography
            variant='body1'
            className={classes.titleLink}
            onClick={() => {
              logEventCustomParams('seeAllTransactionsClicked', {
                userId:  clientId,
              })
              history.push('/transactions/history')
            }}> <Trans {...messages.seeAll} />
          </Typography>
        </LoadingWrapper>
      </Box>
      <Box className={classes.tableContainer}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {recentTransactionsColumns.map((col) => <TableCell key={col.key} align={col.align}>
                  <LoadingWrapper loading={loading}> <Typography variant='body2' className={classes.tableHeadCell}> <Trans {...col.message} /> </Typography> </LoadingWrapper>
                </TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {transactions.map((t, index) => {
                const {type, remoteId, transferAccount, meta, createdAt, amount, currency, status} = t

                return (
                  <TableRow key={`${type} - ${index}`} className={classes.tableBodyRow}>
                    <TableCell> <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}> {this.renderTypeCell(type, remoteId, transferAccount, meta)} </LoadingWrapper> </TableCell>
                    <TableCell align='right'> <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}> {this.renderDateCell(createdAt)} </LoadingWrapper> </TableCell>
                    <TableCell align='right'> <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}> {this.renderAmountCell(amount, currency, type, transferAccount)} </LoadingWrapper></TableCell>
                    <TableCell align='right'> <LoadingWrapper loading={loading} classes={{root: classes.lightGreyLoading}}> {this.renderStatusCell(status)} </LoadingWrapper></TableCell>
                  </TableRow>
                )}
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  }
}

export default compose(
  withRouter,
  withStyles(styles, {withTheme: true}),
)(DesktopView)
