import React, {Component} from 'react'
import {flowRight as compose} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import ChatIcon from '@mui/icons-material/Chat'
import withStyles from '@mui/styles/withStyles'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import messages from '../../assets/messages'
import bgImageLight from '../../assets/images/page-not-found-light.svg'
import bgImageDark from '../../assets/images/page-not-found-dark.svg'
import PageTitle from './PageTitle'
import AppContext from '../Common/contexts/AppContext'
import {isDarkTheme} from '../../common/utils/general'
import {isMobile} from '../../common/utils'

const style = (theme) => ({
  content: {
    [theme.breakpoints.down('md')]: {
      width: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      width: 700,
      margin: 'auto'
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
    },
    '& > .MuiGrid-item:not(:first-child)': {
      padding: theme.spacing(1),
    },
  },
  image: {
    display: 'block',
    margin: '0 auto',
    width: 300,
  },
  children: {
    textAlign: 'center'
  },
})

export class ErrorPage extends Component {
  static contextType = AppContext
  render() {
    const {classes} = this.props
    const {themePreference} = this.context
    const bgImage = isDarkTheme(themePreference) ? bgImageDark : bgImageLight

    return (
      <Grid container direction="column" className={classes.content}>
        <Grid item xs={12}>
          <PageTitle
            withoutPadding={isMobile()}
          >
            <Trans {...messages.pageError} />
          </PageTitle>
        </Grid>
        <Grid item xs={12}>
          <img
            className={classes.image}
            src={bgImage}
            alt={<Trans {...messages.pageError} />}
          />
        </Grid>
        <Grid item xs={12} sm={11} className={classes.children}>
          <Typography variant="body2">
            <Trans {...messages.pageErrorInfo} />
          </Typography>
        </Grid>
        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => window.location = window.location.origin}
              fullWidth
            >
              <ArrowBackIcon />
              &nbsp;
              <Trans {...messages.backToDashboard} />
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => window.location = `${window.location.origin}/support`}
              fullWidth
            >
              <ChatIcon />
              &nbsp;
              <Trans {...messages.contactSupport} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withStyles(style),
  withNamespaces(),
)(ErrorPage)
