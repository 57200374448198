import config from '../../config'
import {get, map} from 'lodash'

const importAll = (r) => {
  const images = {}
  r.keys().map((item) => { images[item.replace('./', '')] = r(item) }) // eslint-disable-line
  return images
}

const Images = importAll(require.context('../../assets/images', false, /\.(png|jpe?g|svg)$/))
const defaultImages = map(Images, (key, value) => ({[value]: get(key, 'default') || key}))

export default get(config, 'common.defaultImages') ?  Object.assign({}, ...defaultImages) : Images
