import React, {Component} from 'react'
import {get, filter, flowRight as compose} from 'lodash'
import Grid from '@mui/material/Grid'
import {Typography} from '@mui/material'
import {graphql} from 'react-apollo'
import {withNamespaces, Trans} from 'react-i18next'
import withStyles from '@mui/styles/withStyles'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY} from '../../../../graphql/queries'
import {CHANGE_RAW_BILLING_INFO_MUTATION, CREATE_RAW_SUBSCRIPTION_MUTATION, CHANGE_RAW_SUBSCRIPTION_MUTATION} from '../../../../graphql/mutations'
import messages from '../../../../assets/messages'
import {rawSubscriptionPlans} from '@bdswiss/common-enums'
import PageSubTitle from '../../../Common/PageSubTitle'
import PlanCard from '../../../Common/PlanCard'
import BillingInfoCard from '../../../Common/BillingInfoCard'
import ChargeBeeProvider from '../../../Payments/Deposits/PaymentProviders/ChargeBeeProvider'
import RecurlyProvider from '../../../Payments/Deposits/PaymentProviders/RecurlyProvider'
import config from '../../../../config'

const styles = theme => ({
  highlightedText: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  leftSide: {
    paddingTop: '1rem'
  },
  freeSubnote: {
    paddingBottom: '1.5rem'
  },
  changeDiv: {
    padding: '15px 0'
  }
})

export class Payment extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tokenThreeD: '',
      subscriptionError: '',
      loadingProp: false,
      openDialogThreeD: false,
    }
  }

  changeBillingInfo(prop, value, tokenThreeD) {
    this.setState({[prop]: value, subscriptionError: '', loadingProp: true})
    const {history, account, changeBillingInfo, location} = this.props
    const {token} = this.state
    return changeBillingInfo({variables: {accountId: account.id, token, tokenThreeD}}).then((res) => {
      const tokenThreeD = get(res, 'data.updateRawSubscriptionBillingInfo.tokenThreeD')
      if (tokenThreeD) {
        this.setState({tokenThreeD, loadingProp: false, openDialogThreeD: true})
        return
      } else {
        if (location && location.state && location.state.changePlan)
          this.props.changeRawSubscription({
            variables: {accountId: account.id, plan: location.state.changePlan.key}
          }).then(() => {
            window.location.href = `/accounts/${account.id}`
          }).catch((err) => {
            const subscriptionError = get(err, 'graphQLErrors[0].message') || err.message
            this.setState({subscriptionError, loadingProp: false})
          })
        else {
          history.push('../plan')
          return res
        }
      }
    }).catch((err) => {
      const subscriptionError = get(err, 'graphQLErrors[0].message') || err.message
      this.setState({subscriptionError: subscriptionError, loadingProp: false})
    })
  }

  async createRawAccount(prop, value, tokenThreeD) {
    this.setState({[prop]: value, subscriptionError: '', loadingProp: true, openDialogThreeD: false, tokenThreeD: ''})
    const {history, account, createRawSubscription} = this.props
    const {token} = this.state
    let plan = get(history.location, 'state.changePlan.key') || get(account, 'subscription.plan') || account.accountSubtype
    if (!rawSubscriptionPlans[plan]) {
      plan = filter(rawSubscriptionPlans, {accountSubtype: plan})[0].key
    }
    return createRawSubscription({variables: {accountId: account.id, plan, tokenId: token, tokenThreeD}}).then((res) => {
      const tokenThreeD = get(res, 'data.createRawSubscription.tokenThreeD')
      if (tokenThreeD) {
        this.setState({tokenThreeD, loadingProp: false, openDialogThreeD: true})
      } else {
        window.location.href = `/accounts/${account.id}`
      }
      return res
    }).catch((err) => {
      const subscriptionError = get(err, 'graphQLErrors[0].message') || err.message
      this.setState({subscriptionError: subscriptionError, loadingProp: false})
      return err
    })
  }

  onThreeDError(err) {
    this.setState({subscriptionError: err, loadingProp: false, openDialogThreeD: false})
  }

  render() {
    const {classes, client, account, history} = this.props
    const {subscriptionError, loadingProp, openDialogThreeD, token, tokenThreeD} = this.state
    let plan = (history.location.state && history.location.state.changePlan && history.location.state.changePlan.key)
      || (account.subscription && account.subscription.plan) || account.accountSubtype

    if (!rawSubscriptionPlans[plan]) {
      plan = filter(rawSubscriptionPlans, {accountSubtype: plan})[0]
    }
    else {
      plan = rawSubscriptionPlans[plan]
    }
    const isUpdateAction = account.subscription && !account.subscription.isExpired
    const subscriptionProvider = config.common.subscriptionProvider

    return (
      <Grid container justifyContent='space-between' spacing={0}>
        <Grid item xs={12} sm={8} lg={6}>
          <PageSubTitle>
            <Trans
              {...messages.paymentFor}
              values={{plan: get(plan, 'label')}}
              components={[<span onClick={() => history.goBack()}
                className={classes.highlightedText}>plan</span>]}
            />
            {get(plan, 'isFree') && <Typography variant="caption" className={classes.freeSubnote}>
              *<Trans {...messages.freeSubnote}
                components={[
                  <span className={classes.hightlight}>not</span>,
                  <a href={`/accounts/${account.id}/plan`} target='_blank' rel="noopener noreferrer" className={classes.hightlight}>service tier</a>,
                ]} /></Typography>}
          </PageSubTitle>
          {subscriptionProvider === 'recurly' &&
            <RecurlyProvider client={client} accountId={account.id}
              recurlyAction={(prop, event, tokenThreeD) =>
                (account.subscription && !account.subscription.isExpired) ?
                  this.changeBillingInfo(prop, event, tokenThreeD) : this.createRawAccount(prop, event, tokenThreeD)}
              recurlyError={subscriptionError}
              loadingProp={loadingProp}
              parentToken={token}
              openDialogThreeD={openDialogThreeD}
              onThreeDError={(err) => this.onThreeDError(err)}
            />}
          {subscriptionProvider === 'chargebee' && <ChargeBeeProvider
            chargeBeeAction={(prop, event, tokenThreeD) => isUpdateAction
              ? this.changeBillingInfo(prop, event, tokenThreeD)
              : this.createRawAccount(prop, event, tokenThreeD)
            }
            tokenThreeD={tokenThreeD}
            isUpdateAction={isUpdateAction}
          />}
        </Grid>
        <Grid item xs={12} sm={6} lg={4} className={classes.leftSide}>
          {account && account.subscription && account.subscription.billingInfo && <BillingInfoCard billingInfo={account.subscription.billingInfo} />}
          <PlanCard plan={plan} showActions={false} single />
        </Grid>
      </Grid>
    )
  }
}

export default compose(
  withStyles(styles),
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      clientLoading: loading,
      clientError: error,
      client: get(data, 'viewer'),
    }),
    options: {
      fetchPolicy: 'network-only',
    },
  }),
  graphql(CHANGE_RAW_BILLING_INFO_MUTATION, {
    name: 'changeBillingInfo',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  }),
  graphql(CREATE_RAW_SUBSCRIPTION_MUTATION, {
    name: 'createRawSubscription',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  }),
  graphql(CHANGE_RAW_SUBSCRIPTION_MUTATION, {
    name: 'changeRawSubscription',
    options: {
      refetchQueries: [{query: ACCOUNTS_QUERY}],
    }
  }),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data, ownProps: {match}}) => {
      const {accountId} = match.params
      const account = get(data, 'viewer.accounts', []).find(account => account.id === Number(accountId))
      return {
        error,
        loading,
        account,
      }
    }
  }),
)(Payment)
